import React from 'react';

const PasswordPolicy = () => (
  <div style={{textAlign: 'left', paddingTop: 15}}>
    <h6>Your password should include:</h6>
    8 characters<br/>
    A Number <br/>
    A special character <br/>
    An uppercase letter <br/>
    A lowercase letter
  </div>
);

export default PasswordPolicy;
