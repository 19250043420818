import {Authentication} from './redux/actions';
import {authenticateWithToken, cognitoConfig, cognitoUserPool} from './cognito';
import getHashToken from '../utils/getHashToken';

const getCognitoUser = store =>
  new Promise((resolve, reject) => {
    const access_token = getHashToken('access_token');
    const id_token = getHashToken('id_token');

    // Keep cognito configuration in redux store
    store.dispatch(
      Authentication.configure({
        config: cognitoConfig,
        userPool: cognitoUserPool
      })
    );

    if (access_token || id_token) {
      // Login user with a token if it's present in url query params
      authenticateWithToken(access_token, id_token).then((user) => {
        if (user) {
          store.dispatch(Authentication.login(user));
        }
        resolve();
      });
    } else {
      // Look for a logged in user in local storage
      const user = cognitoUserPool.getCurrentUser();

      if (user) {
        store.dispatch(Authentication.login(user));
      }
      resolve();
    }
  });

export default getCognitoUser;
