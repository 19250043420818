import React from 'react';
import {connect} from 'react-redux';

import {Icon, Menu} from 'antd';
import {Link} from 'react-router-dom';
import {logOut} from '../../lib/auth/logOut';
import {Authentication} from '../../lib/auth/redux/actions'
import store from '../store';

const SubMenu = Menu.SubMenu;

class NavRight extends React.Component {
  onLogout = () => {
    logOut();
  };

  render() {
    const userName = store.getState().cognito.user !== null ? store.getState().cognito.user.username : "SHK";
    return (
      <Menu mode="horizontal" theme="dark">
        <SubMenu
          style={{float: 'right'}}
          title={
            <span style={{alignContent: 'left'}}>
              <Icon type="user"/>
              Billing System
            </span>
          }
        >
          <Menu.Item key="profile:1">
            {userName}
          </Menu.Item>
          <Menu.Item key="profile:2">
            <Link to="" onClick={this.onLogout} className="text-left">
              Logout
            </Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    );
  }
}

const mapStateToProps = state => ({
  user: state.cognito.user
});
const mapDispatchToProps = dispatch => ({
  handleLogout: (user) => {
    dispatch(Authentication.logout(user));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavRight);
