import store from "../store";
import APP_CONFIG from "../../app_config";
import accessToken from "../../lib/utils/auth";

const setRequestHeaders = () => {
  const storeState = store.getState();
  const headers = {};

  if (APP_CONFIG.apiAuth) {
    headers['X-TWM-Internal-Authorizer'] = accessToken(storeState.cognito.user, storeState.cognito.userPool);
  }

  return headers;
}

export default setRequestHeaders;
