import React from 'react';
import NavRight from './HeaderRight';


const Header = () => {

  return (
    <div>
      <div className="header-right" style={{backgroundColor: '#0f0f0f'}}>
        <NavRight/>
      </div>
    </div>
  )
};

export {Header as default}
