import React, {Component} from 'react';
import ResetPassword from './ResetPassword';

class ResetPasswordPage extends Component {
  render() {
    return (
      <div className="page-login">
        <div className="main-body">
          <div key="user-login-logged-out">
            <div className="container">
              <ResetPassword/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPasswordPage;
