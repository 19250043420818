import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import {message} from 'antd';
import {CognitoUser} from 'amazon-cognito-identity-js';

import store from '../../../../billing/store';
import ResetPasswordForm from './Form';
import ChangePasswordForm from '../ChangeTempPassword/Form';
import PasswordPolicy from '../PasswordPolicy';
import {confirmPassword, forgotPassword} from '../../cognito';
import Logo from '../../../components/Logo';

class ResetPasswordIndex extends Component {
  state = {
    redirectToLogin: false,
    confirmLoading: false,
    cognitoUser: null,
    verificationCode: null,
    currentForm: 'enter_username'
  };

  saveFormRef = (form) => {
    this.form = form;
  };

  validateForm = (event, callback) => {
    event.preventDefault();

    this.setState({confirmLoading: true});

    this.form.validateFields((err, values) => {
      if (err) {
        this.setState({confirmLoading: false});
      } else {
        callback(values);
      }
    });
  };

  handleResetPassword = (event) => {
    this.validateForm(event, (values) => {
      console.log(values);
      const cognitoUser = new CognitoUser({
        Username: values.username,
        Pool: store.getState().cognito.userPool
      });

      forgotPassword(cognitoUser)
        .then(() => {
          // Show the change password form
          this.setState({
            cognitoUser,
            currentForm: 'change_password',
            confirmLoading: false
          });
        })
        .catch((err) => {
          message.error(err.message);
          this.setState({confirmLoading: false});
        });
    });
  };

  handleChangePassword = (event) => {
    this.validateForm(event, (values) => {
      confirmPassword(this.state.cognitoUser, values.verificationCode, values.password)
        .then(() => {
          message.success('Password updated successfully, please login');
          this.setState({redirectToLogin: true});
        })
        .catch((err) => {
          message.config({duration: 8});
          message.error(<PasswordPolicy/>);
          this.setState({confirmLoading: false});
        });
    });
  };

  render() {
    // How to redirect with ReactRouter v4:
    // https://tylermcginnis.com/react-router-programmatically-navigate/

    if (this.state.redirectToLogin) {
      return <Redirect to="/login"/>;
    }

    return (
      <div className="body-auth body-inner">
        <div className="box box-default">
          <div className="box-body">
            <section className="text-center">
              <Logo/>
            </section>

            {this.state.currentForm === 'enter_username' && (
              <ResetPasswordForm
                ref={this.saveFormRef}
                handleSubmit={this.handleResetPassword}
                confirmLoading={this.state.confirmLoading}
              />
            )}

            {this.state.currentForm === 'change_password' && (
              <ChangePasswordForm
                ref={this.saveFormRef}
                handleSubmit={this.handleChangePassword}
                confirmLoading={this.state.confirmLoading}
                verificationCode
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPasswordIndex);
