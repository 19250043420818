import React, {Component} from 'react';
import axios from 'axios';
import APP_CONFIG from "../../app_config";
import setRequestHeaders from "../utils/setAuthToken";
import {Button, Card, Col, Row, Spin, Alert} from "antd";
import ErrorHandler from "./ErrorHandler";
import store from "../store";
import fileDownload from "js-file-download";

const lbmaRateUrl = APP_CONFIG.apiHosts + "/lbma/rate?month=";
const lbmaRateCsvUrl = APP_CONFIG.apiHosts + "/export/lbmarates/csv?month=";

class LbmaRates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMonth: store.getState().monthHandler.format('MMYYYY'),
            lbmaRates: [],
            isLoading: false,
            status: null
        };
        this.columns = [];

        store.subscribe(() => {
            this.setState({
                selectedMonth: store.getState().monthHandler.format('MMYYYY')
            });
        });
    }

    componentDidMount() {
        this.getLbmaRatesByMonth(this.state.selectedMonth)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.selectedMonth !== prevState.selectedMonth) {
            this.getLbmaRatesByMonth(this.state.selectedMonth)
        }
    }

    getLbmaRatesByMonth = (month) => {
        const headers = setRequestHeaders();
        this.setState({isLoading: true})
        axios.get(lbmaRateUrl + month, {headers})
            .then(rates => {
                this.setState({
                    lbmaRates: (rates && rates.data) ? rates.data : [],
                    isLoading: false,
                    status: rates.status
                });
            }).catch(error => {
            this.setState({
                lbmaRates: [],
                isLoading: false,
                status: error.status
            })
        })
    }

    downloadLbmaRates = () => {
        const headers = setRequestHeaders();
        axios.get(lbmaRateCsvUrl + this.state.selectedMonth, {headers})
            .then((response) => {
            const fileName = response.headers["content-disposition"].match(/=(.*)/)[1];
            return fileDownload(response.data, `${fileName}`);
        }).catch((error) => {
            console.log('Error while downloading CSV: ', error);
        });
    };

    gridStyle = (date) => {
        return {
            width: '16.65%',
            textAlign: 'left',
            border: '2px solid rgb(245,245,245)',
            backgroundColor: "rgb(253,253,253)"
        }
    };

    render() {
        const { isLoading, lbmaRates, status } = this.state;
        const dataGrid =
            lbmaRates && lbmaRates.length > 0 ?
                <Card>
                    {lbmaRates.map((temp, index) => (
                        <Card.Grid style={this.gridStyle(temp.date)} key={index}>
                            <b>
                                <h4 align={"right"}
                                    style={{color: "#1890ff", float: "right"}}>{temp.date.match(/(\d{1,4})-(\d{1,2})-(\d{2})/)[3]}</h4>
                            </b><br/><br/><br/>
                            <Row>
                                <Col span={16} align={"left"}>
                                    <p>
                                        Default Rate:
                                    </p>
                                </Col>
                                <Col span={8}>
                                    <b>
                                        {temp.productRate}
                                    </b>
                                </Col>
                            </Row>
                        </Card.Grid>
                    ))}
                </Card>
                :(isLoading) ?
                <Col span={10} offset={6}>
                    <br/>
                    <Alert
                        message="Info"
                        description="Please wait while fetching the rates."
                        type="info"
                        showIcon
                    />
                </Col>
                :
                <Col span={10} offset={6}>
                    <br/>
                    <ErrorHandler
                        status={status}/>
                </Col>;

        return (
            <div>
                <br/>
                <Row type="flex">
                    &nbsp;
                    <Col span={3}>
                        <label>LBMA Rates for <b>GOLD</b></label>
                    </Col>
                    <Col span={2} offset={1}>
                        <Button style={{marginTop: "-10px"}} onClick={this.downloadLbmaRates} type={'primary'} disabled={!lbmaRates || !lbmaRates.length}>Download Data</Button>
                    </Col>
                </Row>
                <br/>
                <form>
                    <Spin spinning={isLoading}>
                        {dataGrid}
                    </Spin>
                </form>

            </div>
        )
    }

}

export {LbmaRates as default}
