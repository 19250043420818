const APP_CONFIG = {
  "app": "billingsystem",
  "refresh": {
    "api": 9000,
    "action": 2000
  },
  "cognito": {
    "userPool": "us-east-1_itYovIVEH",
    "clientId": "6uqikvvee4injroc2icl0ep7g6",
    "identityPool": "us-east-1:744522960437:userpool/us-east-1_itYovIVEH"
  },
  "name": "dealer",
  "apiHosts": [
    "https://api.billing.tradewind.cloud/billing-system"
    // "https://cors-anywhere.herokuapp.com/https://b247cdda.ngrok.io/billing-system"
  ],
  "apiAuth": true
};

export default APP_CONFIG;
