function getIdTokenKey(obj) {
  const idToken = Object.keys(obj).find((key) => {
    if (key.includes('.idToken')) {
      return key;
    }
  });

  return obj[idToken];
}

const accessToken = (user, userPool) => {
  let accessToken = '';
  if (user) {
    if (user.signInUserSession) {
      accessToken = user.signInUserSession.idToken.jwtToken;
    } else if (user.storage) {
      accessToken = getIdTokenKey(user.storage);
    }
  } else if (userPool) {
    accessToken = getIdTokenKey(userPool.storage);
  }
  return accessToken;
};

export default accessToken;
