import React from 'react';

const Logo = () => {
  return (
    <div className="tradewind-logo">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 794.07 104.86">
        <g data-name="Layer 2">
          <g>
            <g>
              <g>
                <path
                  d="M725.1,65.1c0-11.89,0-23.78,0-35.67,0-1.54.36-2.07,2-2.05,10.09.12,20.2-.19,30.28.25,15.95.69,30.08,9.85,34.9,25.62,6.49,21.23-4.46,43.54-27.94,48.57a55,55,0,0,1-10.48,1.07c-8.9.15-17.8,0-26.7.08-1.58,0-2.05-.45-2-2C725.12,89,725.1,77.05,725.1,65.1Zm16.5.09c0,6.92,0,13.85,0,20.77,0,1.32.28,1.94,1.78,1.9,4.12-.09,8.24.12,12.35-.07,6.21-.28,11.7-2.43,15.77-7.31,5.12-6.13,6.14-13.29,4.63-20.9-1.69-8.51-8.29-15.22-16.69-16.35-5.23-.7-10.58-.43-15.88-.69-1.64-.09-2,.54-2,2.06C741.64,51.46,741.6,58.33,741.6,65.19Z"
                />
              </g>
              <g>
                <path
                  d="M664.74,56.13v44.33c0,2.48,0,2.48-2.4,2.48-4.12,0-8.24,0-12.36,0-1.11,0-1.62-.27-1.62-1.49q0-36.56,0-73.1a7.14,7.14,0,0,1,.18-.95h5.3c2.75,0,5.51-.1,8.24,0a3.76,3.76,0,0,1,2.5,1.24Q680.83,50,696.9,71.44a4.48,4.48,0,0,0,1.63,1.42c0-.66.1-1.32.1-2,0-13.79,0-27.59,0-41.39,0-1.64.43-2.16,2.1-2.11,4.12.11,8.24.08,12.36,0,1.4,0,2,.29,2,1.87q-.06,35.83,0,71.67c0,1.58-.5,2.08-2.06,2-3.52-.09-7-.08-10.57,0a2.93,2.93,0,0,1-2.67-1.39q-16.49-21.89-33-43.75c-.46-.61-.94-1.21-1.41-1.81Z"
                />
              </g>
              <g data-name="I">
                <path
                  d="M637,27.43v73c0,2.5,0,2.5-2.57,2.5-4.06,0-8.12,0-12.18,0-1.35,0-1.76-.45-1.76-1.76q0-36.12.06-72.23c0-.52.59-1.48.92-1.48C626.56,27.4,631.62,27.43,637,27.43Z"
                />
              </g>
              <g>
                <path
                  d="M582,77.65a15.16,15.16,0,0,0,.77-1.64q7-23.12,14-46.26c.52-1.74,1.22-2.48,3.18-2.39,4.24.19,8.48.05,12.73.06.63,0,1.26.08,2.17.13-1.11,3.33-2.12,6.43-3.16,9.52L596.74,81.52c-2.32,6.9-4.66,13.79-6.94,20.7a1.77,1.77,0,0,1-2,1.38c-3.76,0-7.53-.06-11.29,0a2,2,0,0,1-2.16-1.61q-6.09-18-12.28-35.89c-1.1-3.21-2.18-6.44-3.29-9.66-.15-.42-.4-.81-.8-1.64-1.22,3.52-2.3,6.64-3.37,9.76q-6.37,18.63-12.71,37.27a2.17,2.17,0,0,1-2.42,1.78c-3.59-.1-7.18-.12-10.76,0-1.51,0-2.08-.59-2.51-1.89q-4.8-14.53-9.67-29Q509.27,51,501.93,29.28c-.16-.49-.26-1-.45-1.72.76-.06,1.33-.14,1.9-.14,4.66,0,9.32.06,14,0a2.06,2.06,0,0,1,2.34,1.8q7,23.24,14,46.44a3.57,3.57,0,0,0,1.07,1.72q1.65-5,3.28-10c4.23-12.93,8.45-25.86,12.75-38.76a2.36,2.36,0,0,1,1.76-1.25c3.82-.11,7.65,0,11.47-.08,1.21,0,1.6.59,1.94,1.63q7.51,23.23,15.13,46.45C581.34,76,581.6,76.58,582,77.65Z"
                />
              </g>
              <g>
                <path
                  d="M441.28,27.42h54.1c2.33,0,2.33,0,2.33,2.36,0,3.35,0,6.69,0,10,0,2.6,0,2.6-2.7,2.6H460.07c-2.43,0-2.43,0-2.43,2.48,0,3.53.07,7.05,0,10.57-.05,1.67.52,2.15,2.16,2.14,10.27-.07,20.54,0,30.81-.08,1.88,0,2.34.56,2.27,2.34-.14,3.46-.14,6.93,0,10.39.06,1.72-.58,2.08-2.16,2.07-10.21-.06-20.43,0-30.64,0-2.39,0-2.4,0-2.4,2.37,0,3.77.07,7.53,0,11.29-.05,1.65.47,2.15,2.12,2.14,12-.06,24,0,36,0,2.4,0,2.4,0,2.41,2.38,0,3.52,0,7,0,10.57,0,1.49-.52,1.94-2,1.94q-26.61-.06-53.22,0c-1.42,0-1.84-.44-1.84-1.84q.06-36,0-72C441.16,28.63,441.22,28.16,441.28,27.42Z"
                />
              </g>
              <g>
                <path
                  d="M366.5,65c0-11.76,0-23.53,0-35.3,0-1.75.38-2.38,2.26-2.36,10,.14,20.09-.29,30.09.29,14.72.84,26.5,7.22,33,20.94,9.44,19.79,1,45.86-23.6,52.58a52.05,52.05,0,0,1-12.18,1.68c-8.89.25-17.8.08-26.69.08-2.89,0-2.89,0-2.89-3Zm16.69.16c0,6.92,0,13.85,0,20.77,0,1.31.25,1.94,1.77,1.91,4.06-.11,8.13.1,12.18-.07a21.43,21.43,0,0,0,15-6.31c4.84-5,6.32-11.06,6.07-17.75-.4-10.89-7.43-19.17-18.21-20.69-4.86-.68-9.88-.3-14.82-.52-1.65-.07-2,.56-2,2.07C383.23,51.46,383.19,58.33,383.19,65.19Z"
                />
              </g>
              <g>
                <path
                  d="M361.56,102.82c-.84.06-1.36.12-1.89.12-4.48,0-9-.06-13.43,0a2.39,2.39,0,0,1-2.63-1.81c-1.77-4.55-3.66-9.07-5.44-13.63a2,2,0,0,0-2.24-1.5q-14.07.06-28.13,0a2,2,0,0,0-2.26,1.5c-1.76,4.56-3.69,9-5.46,13.61a2.36,2.36,0,0,1-2.6,1.83c-4.29-.1-8.59,0-12.89,0-.58,0-1.16,0-2.11-.09.94-2.33,1.75-4.42,2.62-6.47q14.34-34,28.66-67.92a2.21,2.21,0,0,1,2.43-1.56c3.94.05,7.88.07,11.82,0a2.22,2.22,0,0,1,2.39,1.63Q341.65,55.33,353,82.11l7.95,18.78C361.15,101.43,361.29,102,361.56,102.82Zm-39.47-55-.53.07c-3.08,7.63-6.17,15.26-9.38,23.19h19.36C328.32,63.14,325.2,55.48,322.09,47.81Z"
                />
              </g>
              <g>
                <path
                  d="M262.09,76.17l18,26.6c-1,.08-1.52.17-2.07.17-5.14,0-10.27,0-15.41,0a3,3,0,0,1-2.9-1.6c-4.63-7-9.34-14-14-21.05A2.89,2.89,0,0,0,243,78.78c-3,.08-6.09.1-9.13,0-1.54,0-1.92.54-1.91,2,.06,6.69,0,13.38,0,20.07,0,1.6-.39,2.2-2.1,2.15-4.18-.12-8.36-.08-12.54,0-1.35,0-1.91-.33-1.91-1.79q0-36.11,0-72.21c0-1.14.33-1.58,1.53-1.57,11.94.08,23.89-.09,35.82.23,7.15.2,13.73,2.42,18.9,7.78a22.08,22.08,0,0,1,6,13.68c.69,7.23-.41,14-5.29,19.83a24.82,24.82,0,0,1-8.57,6.34C263.33,75.51,262.82,75.79,262.09,76.17ZM232,53.26c0,3,0,6.08,0,9.12,0,1.09.26,1.64,1.49,1.63,5.61,0,11.22.12,16.82-.05,8.67-.27,13.59-8.82,9.39-16.2-2-3.52-5.44-4.83-9.18-5-5.72-.26-11.45-.12-17.17-.2-1.2,0-1.35.62-1.34,1.58C232,47.17,232,50.22,232,53.26Z"
                />
              </g>
              <g>
                <path data-name="T"
                      d="M179.14,27.42c9.62,0,19.23,0,28.85,0,1.63,0,2.18.42,2.13,2.1-.12,3.82-.09,7.65,0,11.47,0,1.47-.45,2-2,2-6.21-.06-12.42,0-18.63,0-1.64,0-2.16.49-2.15,2.15q.08,27.69,0,55.37c0,2.56,0,2.56-2.52,2.56-4,0-8-.08-12,0-1.72,0-2.11-.57-2.11-2.16q.06-27.6,0-55.19c0-2.74,0-2.74-2.76-2.74-6,0-12,0-17.92,0-1.58,0-2.08-.5-2-2.06.09-3.88.08-7.77,0-11.65,0-1.42.44-1.83,1.84-1.82C159.67,27.44,169.4,27.42,179.14,27.42Z"
                />
              </g>
            </g>

            <g transform='translate(0 0) scale(1) rotate(0) skewX(0)' preserveAspectRatio="xMidYMin">
              <g>
                <path
                  d="M45.62,51.21l-30.45.23s-1,1.38-1.47,2.18C9.25,61.25,4.86,68.92.42,76.56a2.38,2.38,0,0,0,0,2.7q7.05,12.1,14,24.28a2.38,2.38,0,0,0,2.36,1.32q14,0,28,0a2.38,2.38,0,0,0,2.34-1.35c3.93-6.83,14.71-25,14.71-25a1.47,1.47,0,0,0,0-1.57l-15-25A2.38,2.38,0,0,0,45.62,51.21ZM57.34,76.27H33.57c4-6.87,7.85-13.6,11.88-20.59ZM42.68,54.14,30.8,74.74c-4-6.95-7.91-13.69-11.89-20.6ZM16.14,55.68,28,76.25H4.26Zm0,44.45L4.24,79.52H28Zm2.78,1.51L30.79,81.07c4,6.92,7.89,13.65,11.88,20.57Zm26.53-1.51c-4-7-7.9-13.69-11.87-20.58H57.33C53.37,86.42,49.49,93.13,45.45,100.13Z"
                />
              </g>
              <g>
                <path
                  d="M60.53,25.68l-30.45.23s-1,1.38-1.47,2.18C24.16,35.72,19.77,43.39,15.33,51a2.38,2.38,0,0,0,0,2.7q7,12.1,14,24.28a2.4,2.4,0,0,0,2.36,1.32q14,0,28.05,0A2.39,2.39,0,0,0,62.08,78C66,71.15,76.79,53,76.79,53a1.47,1.47,0,0,0,0-1.57l-15-25A2.41,2.41,0,0,0,60.53,25.68ZM72.25,50.74H48.49c4-6.87,7.84-13.6,11.88-20.59ZM57.59,28.61c-4,6.88-7.85,13.61-11.88,20.6L33.82,28.61ZM31.05,30.15,42.93,50.72H19.18Zm0,44.45C27.06,67.67,23.18,61,19.16,54H42.94Zm2.79,1.51c4-6.89,7.86-13.63,11.87-20.57,4,6.92,7.88,13.65,11.88,20.57ZM60.37,74.6,48.49,54H72.24Z"
                />
              </g>
              <g>
                <path
                  d="M104.75,0C89.6.12,74.25,0,74.25,0L72.82,2.41C68.38,10.05,64,17.71,59.54,25.35a2.4,2.4,0,0,0,0,2.7q7,12.12,14,24.28a2.4,2.4,0,0,0,2.36,1.33q14,0,28,0a2.41,2.41,0,0,0,2.34-1.36c3.93-6.83,14.7-25,14.7-25a1.45,1.45,0,0,0,0-1.57l-15-25A2.44,2.44,0,0,0,104.75,0Zm11.72,25.06H92.7c4-6.87,7.85-13.6,11.88-20.59ZM101.81,2.94,89.92,23.54,78,2.94ZM75.27,4.47,87.14,25.05H63.39Zm0,44.45L63.37,28.31H87.16C83.18,35.21,79.29,41.94,75.27,48.92Zm2.78,1.51c4-6.88,7.87-13.63,11.87-20.57L101.8,50.43Zm26.53-1.51L92.71,28.35h23.74Z"
                />
              </g>
              <g>
                <path
                  d="M75.71,0C60.56.12,45.2,0,45.2,0L43.78,2.41C39.34,10.05,35,17.71,30.5,25.35a2.4,2.4,0,0,0,0,2.7q7.05,12.12,14,24.28a2.4,2.4,0,0,0,2.36,1.33q14,0,28,0a2.41,2.41,0,0,0,2.34-1.36c3.92-6.83,14.7-25,14.7-25a1.45,1.45,0,0,0,0-1.57L77,.75A2.44,2.44,0,0,0,75.71,0ZM87.43,25.06H63.66L75.54,4.47ZM72.77,2.94,60.88,23.54,49,2.94ZM46.22,4.47,58.1,25.05H34.35Zm0,44.45L34.33,28.31H58.12C54.13,35.21,50.25,41.94,46.23,48.92ZM49,50.43,60.88,29.86,72.76,50.43Zm26.53-1.51L63.67,28.35H87.41Z"
                />
              </g>
              <g>
                <path
                  d="M46.15,0,15.7.23s-1,1.39-1.47,2.18C9.78,10.05,5.39,17.71,1,25.35a2.38,2.38,0,0,0,0,2.7Q8,40.17,15,52.33a2.41,2.41,0,0,0,2.36,1.33q14,0,28.05,0A2.39,2.39,0,0,0,47.7,52.3c3.93-6.83,14.71-25,14.71-25a1.47,1.47,0,0,0,0-1.57L47.46.75A2.47,2.47,0,0,0,46.15,0ZM57.87,25.06H34.11C38.07,18.19,42,11.46,46,4.47ZM43.21,2.94,31.33,23.54c-4-7-7.9-13.7-11.89-20.6ZM16.67,4.47c4,6.94,7.89,13.67,11.88,20.58H4.8C8.79,18.12,12.66,11.41,16.67,4.47Zm0,44.45C12.68,42,8.8,35.27,4.78,28.31H28.56Zm2.79,1.51L31.33,29.86,43.2,50.43ZM46,48.92c-4-7-7.9-13.69-11.88-20.57H57.86Z"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default Logo;
