import GroupPermissions from '../../permissions';

export const COGNITO_CONFIGURE = 'COGNITO_CONFIGURE';
export const COGNITO_LOGIN = 'COGNITO_LOGIN';
export const COGNITO_REFRESH = 'COGNITO_REFRESH';
export const COGNITO_LOGOUT = 'COGNITO_LOGOUT';
export const COGNITO_CHANGE_TEMP_PASSWORD = 'COGNITO_CHANGE_TEMP_PASSWORD';


export const getUserPermissions = (groups) => {
  let permissions = GroupPermissions[groups[0].trim()]; // there must be at least one group

  // if the first group present did not have meaningful permissions, then ALLOW ALL PERMISSIONS
  // as a default
  if (!permissions) {
    permissions = GroupPermissions.AllowAll;
  }
  if (groups.length > 1) {
    if (groups.includes('MasterGroup')) {
      // The 'MasterGroup' takes priority over any other permission group
      permissions = GroupPermissions.MasterGroup;
    } else {
      groups.shift(); // remove the first group we already stored in `permissions`

      groups.forEach((group) => {
        const trimmedGroupStr = group.trim();
        if (GroupPermissions[trimmedGroupStr]) {
          // Make sure we have this group permissions setup in UI
          Object.keys(GroupPermissions[trimmedGroupStr]).forEach((permission) => {
            // Merge group permissions if a permission is true
            if (GroupPermissions[trimmedGroupStr][permission] === true) {
              permissions[permission] = GroupPermissions[trimmedGroupStr][permission];
            }
          });
        }
      });
    }
  }

  return permissions;
};

const Authentication = {
  configure: config => ({
    type: COGNITO_CONFIGURE,
    config
  }),

  login: user => async (dispatch) => {
    let permissions = {};
    let attributes = [];


    user.getSession((err, session) => {
      if (session.idToken.payload['cognito:groups']) {
        let groups = [];

        if (session.idToken.payload.profile) {
          groups = session.idToken.payload.profile.split(',');
        } else {
          groups = session.idToken.payload['cognito:groups'];
        }

        permissions = getUserPermissions(groups);
      }
    });

    user.getUserAttributes((err, result) => {
      if (!err) {
        attributes = result;
      }

      dispatch(Authentication.dispatchLogin(user, attributes, permissions));
    });
  },

  refresh: user => (dispatch) => {
    dispatch(Authentication.dispatchRefresh(user));
  },

  dispatchRefresh: user => ({
    type: COGNITO_REFRESH,
    user
  }),

  dispatchLogin: (user, attributes, permissions) => ({
    type: COGNITO_LOGIN,
    user,
    attributes,
    permissions
  }),

  logout: user => ({
    type: COGNITO_LOGOUT,
    user
  }),

  changeTempPassword: userData => ({
    type: COGNITO_CHANGE_TEMP_PASSWORD,
    userData
  })
};

export {Authentication};
