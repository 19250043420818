import React from 'react';
import {connect} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
import APP_CONFIG from '../../../app_config';

const RestrictedRoute = ({component: Component, identity, cognito, services, componentProps, ...rest}) => {
  const servicesProvided = identity ? identity.data.services_provided : null;
  const isAuthenticated = cognito.authenticated;
  const isAuthEnabled = APP_CONFIG.apiAuth;
  const app = APP_CONFIG.app;
  console.log(cognito);
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated || !isAuthEnabled) {
          return <Component {...componentProps} {...props} />;
        }

        // redirect to login if not authenticated
        return <Redirect to="/login"/>;
      }}
    />
  );
};

const mapStateToProps = state => ({
  cognito: state.cognito,
  identity: state.identity
});

export default connect(
  mapStateToProps,
  null
)(RestrictedRoute);
