import React from 'react';
import ReactDOM from 'react-dom';
// import App from '../billing/App';
import Billing from './billing/application';
import getCognitoUser from './lib/auth/getCognitoUser';

import 'normalize.css/normalize.css';

import * as serviceWorker from './serviceWorker';
import store from './billing/store';
import APP_CONFIG from './app_config';

if (APP_CONFIG.apiAuth) {
  getCognitoUser(store).then(() => {
    ReactDOM.render(<Billing/>, document.getElementById('root'));
  })
} else {
  ReactDOM.render(<Billing/>, document.getElementById('root'));
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
