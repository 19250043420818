import React, {Component} from 'react'
import jwtTokenParams from "../../lib/utils/jwtTokenParams";
import {Breadcrumb, Button, Col, Icon, Layout, Menu, DatePicker, Row} from "antd";
import Header from "./Header";
import {Provider as ReduxProvider} from "react-redux";
import {HashRouter, Link} from "react-router-dom";
import {connect, Provider} from "react-redux";
import APP_CONFIG from "../../app_config";
import NavLeft from "./NavLeft";
import Logo from "./Logo";
import {getDate, setDate} from "../store/actions";
import store from "../store";
import moment from "moment";
import reduxStore from '../store'

const {Content, Sider, Footer} = Layout;
const {SubMenu} = Menu;
const {MonthPicker} = DatePicker;

class ApplicationLayout extends Component {

  handleMonthChange = (date) => {
    // let formattedMonth;
    // const currentMonth = moment().subtract(4, 'months').format('MMYYYY');
    store.dispatch(setDate(date));
    console.log(store.getState().monthHandler);
    // // this.props.setDate(currentMonth.toString());
    // if (typeof (date) != "undefined" && date != null) {
    //   formattedMonth = date.format('MM YYYY');
    // } else {
    //   formattedMonth = this.props.getDate()
    // }
    // this.setState({
    //   selectedMonth: formattedMonth
    // })
  };

  disabledDate = (current) => {
    // Can not select days after today and today
    return current && current > moment().subtract(1, 'months').endOf('day');
  };

  render() {
    console.log(this.props.location.pathname);
    const {identity, unauthorized, location, cognito, rfq} = this.props;
    return (
      <ReduxProvider store={reduxStore}>
        <Layout className='full-height'>
          <Sider>
            <section className="text-center">
              <Link to={"/"}>
                <Logo/>
              </Link>
            </section>
            <NavLeft/>
          </Sider>
          <Layout>
            <Header cognito={this.props.cognito} identity={this.props.identity}/>
            <Row style={{
              margin: '6px 12px 12px 12px',
              padding: 6,
              background: '#ffffff'
            }}>
              <Col span={24} style={{padding: '12px'}}>

                <Col span={3}>
                  <span>Select Billing Period</span>&nbsp;&nbsp;
                </Col>
                <Col span={4}>
                  <MonthPicker
                    defaultValue={store.getState().monthHandler}
                    disabledDate={this.disabledDate}
                    onChange={this.handleMonthChange}
                    name="selectedMonth"
                    format={'MMM YYYY'}
                    allowClear={false} />
                </Col>
              </Col>
            </Row>
            <Content
              style={{
                margin: '6px 12px 12px 12px',
                padding: 6,
                background: '#fff',
                minHeight: 280,
                overflow: 'scroll'
              }}
            >
              {this.props.children}
            </Content>
            <Footer style={{textAlign: 'center', padding: 2}}>© 2019 Tradewind Markets, Inc. | All Rights
              Reserved.</Footer>
          </Layout>
        </Layout>
      </ReduxProvider>
    )
  }
}

const mapStateToProps = (state, props) => ({
  monthHandler: state.monthHandler,
  cognito: state.cognito,
  identity: state.identity
});

const mapDispatchToProps = dispatch => ({
  getDate: () => dispatch(getDate()),
  setDate: date => dispatch(setDate(date))
});

const ApplicationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationLayout);

export default ApplicationContainer;
