import {Button, Col, Divider, Icon, Input, message, Popover, Row, Spin, Tooltip} from 'antd';
import Axios from 'axios';
import React, {Component} from 'react';
import RateRangeSetter from './FormFields/RateRangeSetter';
import SingleInput from './FormFields/SingleInput';
import BigNumber from 'bignumber.js';
import APP_CONFIG from '../../app_config';
import setRequestHeaders from '../utils/setAuthToken';
import store from "../store";
import moment from "moment";
import {connect} from "react-redux";

const updateAdminRateURL = APP_CONFIG.apiHosts + "/cp/rate/update";

class FormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cpActive: false,
      cpId: '',
      cpName: '',
      defaultData: [],
      depositRate: [],
      withDrawalRate: [],
      interCpTransferOut: [],
      interCpTransferIn: '',
      internalTransferOut: [],
      internalTransferIn: [],
      storage: 0,
      internalTradeBuyRates: [],
      internalTradeSellRates: [],
      interCpTradeBuyRates: [],
      interCpTradeSellRates: []
    };
  }

  componentWillReceiveProps(props) {
    if (props.testProp !== this.props.testProp) {
      this.setState({testProp: props.testProp});
    }
    if (props.cpId !== this.props.cpId) {
      this.setState({cpId: props.cpId});
    }
    if (props.cpName !== this.props.cpName) {
      this.setState({cpName: props.cpName});
    }
    if (props.cpActive !== this.props.cpActive) {
      this.setState({cpActive: props.cpActive});
    }
  }

  componentDidMount() {
    const adminRates = this.props.testProp;
    const storeState = store.getState();
    console.log(storeState);

    this.setState({
      cpActive: this.props.cpActive,
      cpId: this.props.cpId,
      cpName: this.props.cpName,
      defaultData: JSON.parse(JSON.stringify(adminRates)),
      internalTradeBuyRates: adminRates.internal_trade["BUY"],
      internalTradeSellRates: adminRates.internal_trade["SELL"],
      interCpTradeBuyRates: adminRates.inter_cp_trade["BUY"],
      interCpTradeSellRates: adminRates.inter_cp_trade["SELL"],
      depositRate: adminRates.deposit,
      withDrawalRate: adminRates.withdrawal,
      interCpTransferOut: adminRates.inter_cp_transfer_out,
      interCpTransferIn: adminRates.inter_cp_transfer_in,
      internalTransferOut: adminRates.internal_transfer_out,
      internalTransferIn: adminRates.internal_transfer_in,
      storage: adminRates.storage
    });
  }

  handleDepositRateChange = (e) => {
    this.setState({depositRate: e.target.value});
  };

  handleWithdrawalRateChange = (e) => {
    this.setState({withDrawalRate: e.target.value});
  };

  handleInterCpTransferOutRateChange = (e) => {
    this.setState({interCpTransferOut: e.target.value});
  };

  handleInterCpTransferInRateChange = (e) => {
    this.setState({interCpTransferIn: e.target.value});
  };

  handleInternalTransferOutRateChange = (e) => {
    this.setState({internalTransferOut: e.target.value});
  };

  handleInternalTransferInRateChange = (e) => {
    this.setState({internalTransferIn: e.target.value});
  };

  handlestorageChange = (e) => {
    this.setState({storage: e.target.value});
  };

  handleInternalBuyFromRangeChange = (e) => {
    const range_from = e.target.value;
    let tempData = [...this.state.internalTradeBuyRates];
    tempData[Number(e.target.name)].range_from = range_from;
    if (Number(e.target.name) > (this.state.internalTradeBuyRates.length - 1)) {
      tempData[Number(e.target.name) - 1].range_to = (BigNumber(range_from).minus(0.001)).toString();
    }
    this.setState({
      internalTradeBuyRates: this.state.internalTradeBuyRates.map((el, index) => (index === Number(e.target.name) ?
        {...el, range_from} : el))
    });
  };

  handleInternalBuyToRangeChange = (e) => {
    const range_to = e.target.value === "" ? null : e.target.value;
    let tempData = [...this.state.internalTradeBuyRates];
    tempData[Number(e.target.name)].range_to = range_to;
    if (Number(e.target.name) < (this.state.internalTradeBuyRates.length - 1)) {
      tempData[Number(e.target.name) + 1].range_from = (BigNumber(range_to).plus(0.001)).toString();
    }
    this.setState({
      internalTradeBuyRates: this.state.internalTradeBuyRates.map((el, index) =>
        (index === Number(e.target.name) ?
          {...el, range_to}
          : el))
    });
  };

  handleInternalBuyRateChange = (e) => {
    const rate = e.target.value;
    this.setState({
      internalTradeBuyRates: this.state.internalTradeBuyRates.map((el, index) => (index === Number(e.target.name) ?
        {...el, rate} : el))
    });
  };

  handleInternalSellFromRangeChange = (e) => {
    const range_from = e.target.value;
    let tempData = [...this.state.internalTradeSellRates];
    tempData[Number(e.target.name)].range_from = range_from;
    if (Number(e.target.name) > (this.state.internalTradeSellRates.length - 1)) {
      tempData[Number(e.target.name) - 1].range_to = (BigNumber(range_from) - 0.001).toString();
    }
    this.setState({
      internalTradeSellRates: this.state.internalTradeSellRates.map((el, index) => (index === Number(e.target.name) ?
        {...el, range_from} : el))
    });
  };

  handleInternalSellToRangeChange = (e) => {
    const range_to = e.target.value === "" ? null : e.target.value;
    let tempData = [...this.state.internalTradeSellRates];
    tempData[Number(e.target.name)].range_to = range_to;
    if (Number(e.target.name) < (this.state.internalTradeSellRates.length - 1)) {
      tempData[Number(e.target.name) + 1].range_from = (BigNumber(range_to) + 0.001).toString();
    }
    this.setState({
      internalTradeSellRates: this.state.internalTradeSellRates.map((el, index) => (index === Number(e.target.name) ?
        {...el, range_to} : el))
    });
  };

  handleInternalSellRateChange = (e) => {
    const rate = e.target.value;
    this.setState({
      internalTradeSellRates: this.state.internalTradeSellRates.map((el, index) => (index === Number(e.target.name) ?
        {...el, rate} : el))
    });
  };

  handleInterCpBuyFromRangeChange = (e) => {
    const range_from = e.target.value;
    let tempData = [...this.state.interCpTradeBuyRates];
    tempData[Number(e.target.name)].range_from = range_from;
    if (Number(e.target.name) > (this.state.interCpTradeBuyRates.length - 1)) {
      tempData[Number(e.target.name) - 1].range_to = (BigNumber(range_from) - 0.001).toString();
    }
    this.setState({
      interCpTradeBuyRates: this.state.interCpTradeBuyRates.map((el, index) => (index === Number(e.target.name) ?
        {...el, range_from} : el))
    });
  };

  handleInterCpBuyToRangeChange = (e) => {
    const range_to = e.target.value === "" ? null : e.target.value;
    let tempData = [...this.state.interCpTradeBuyRates];
    tempData[Number(e.target.name)].range_to = range_to;
    if (Number(e.target.name) < (this.state.interCpTradeBuyRates.length - 1)) {
      tempData[Number(e.target.name) + 1].range_from = (BigNumber(range_to) + 0.001).toString();
    }
    this.setState({
      interCpTradeBuyRates: this.state.interCpTradeBuyRates.map((el, index) => (index === Number(e.target.name) ?
        {...el, range_to} : el))
    });
  };

  handleInterCpBuyRateChange = (e) => {
    const rate = e.target.value;
    this.setState({
      interCpTradeBuyRates: this.state.interCpTradeBuyRates.map((el, index) => (index === Number(e.target.name) ?
        {...el, rate} : el))
    });
  };

  handleInterCpSellFromRangeChange = (e) => {
    const range_from = e.target.value;
    let tempData = [...this.state.interCpTradeSellRates];
    tempData[Number(e.target.name)].range_from = range_from;
    if (Number(e.target.name) > (this.state.interCpTradeSellRates.length - 1)) {
      tempData[Number(e.target.name) - 1].range_to = (BigNumber(range_from) - 0.001).toString();
    }
    this.setState({
      interCpTradeSellRates: this.state.interCpTradeSellRates.map((el, index) => (index === Number(e.target.name) ?
        {...el, range_from} : el))
    });
  };

  handleInterCpSellToRangeChange = (e) => {
    const range_to = e.target.value === "" ? null : e.target.value;
    let tempData = [...this.state.interCpTradeSellRates];
    tempData[Number(e.target.name)].range_to = range_to;
    if (Number(e.target.name) < (this.state.interCpTradeSellRates.length - 1)) {
      tempData[Number(e.target.name) + 1].range_from = (BigNumber(range_to) + 0.001).toString();
    }
    this.setState({
      interCpTradeSellRates: this.state.interCpTradeSellRates.map((el, index) => (index === Number(e.target.name) ?
        {...el, range_to} : el))
    });
  };

  handleInterCpSellRateChange = (e) => {
    const rate = e.target.value;
    this.setState({
      interCpTradeSellRates: this.state.interCpTradeSellRates.map((el, index) => (index === Number(e.target.name) ?
        {...el, rate} : el))
    });
  };

  newRangeSetValidator = (fieldValue, fieldName) => {
    let addNewRateRangeButton;
    if (typeof fieldValue && fieldValue.length > 0) {
      addNewRateRangeButton = fieldValue.slice(-1)[0].range_to ?
        <Button type="dashed" name={fieldName} onClick={this.addNewRangeSet} block>
          {/*<h3>*/}
          {/*  <Icon type="plus-circle" theme="twoTone" twoToneColor={"#506feb"} name={fieldName} onClick={this.addNewRangeSet}/>*/}
          {/*</h3>:*/}
          <Icon type="plus-circle"/>Add new rate range
        </Button> :
        <Tooltip placement="bottom"
          // content="Please add all value in previous range set inorder to add new range set"
                 title="Please add all value in previous range set inorder to add new range set">
          <Button type="dashed" block>
            <Icon type="plus-circle"/>Add new rate range
            {/*<h3><Icon type="plus-circle" theme="twoTone" twoToneColor={"#506feb"} name={fieldName} /></h3>*/}

          </Button>
        </Tooltip>
    } else addNewRateRangeButton = <div/>;

    return addNewRateRangeButton
  };

  addNewRangeSet = (e) => {
    const addToFieldName = e.target.name;
    e.preventDefault();

    if (addToFieldName === "internalTradeBuyRates") {
      const length = this.state.internalTradeBuyRates.length;
      const newInput = {
        rate: "0",
        range_from: (Number(this.state.internalTradeBuyRates[length - 1].range_to) + 0.001).toString(),
        range_to: null
      };

      this.setState({internalTradeBuyRates: this.state.internalTradeBuyRates.concat(newInput)});
    } else if (addToFieldName === "internalTradeSellRates") {
      const length = this.state.internalTradeSellRates.length;
      const newInput = {
        rate: "0",
        range_from: (Number(this.state.internalTradeSellRates[length - 1].range_to) + 0.001).toString(),
        range_to: null
      };

      this.setState({internalTradeSellRates: this.state.internalTradeSellRates.concat(newInput)});
    } else if (addToFieldName === "interCpTradeBuyRates") {
      const length = this.state.interCpTradeBuyRates.length;
      const newInput = {
        rate: "0",
        range_from: (Number(this.state.interCpTradeBuyRates[length - 1].range_to) + 0.001).toString(),
        range_to: null
      };

      this.setState({interCpTradeBuyRates: this.state.interCpTradeBuyRates.concat(newInput)});
    } else if (addToFieldName === "interCpTradeSellRates") {
      const length = this.state.interCpTradeSellRates.length;
      const newInput = {
        rate: "0",
        range_from: (Number(this.state.interCpTradeSellRates[length - 1].range_to) + 0.001).toString(),
        range_to: null
      };

      this.setState({interCpTradeSellRates: this.state.interCpTradeSellRates.concat(newInput)});
    } else {

    }
  };

  removeRangeSetValidator = (fieldValue) => {
    let removeNewRateRangeButton;
    if (fieldValue.length <= 1) {
      removeNewRateRangeButton =
        <Tooltip placement="bottom"
                 content="Range set cannot be deleted to empty"
                 title="Range set cannot be deleted to empty">
          <Button type="dashed" block>
            {/*<h3><Icon type="delete" theme="twoTone" twoToneColor={"#506feb"}/></h3>*/}
            <Icon type="minus-circle"/>Remove last rate range
          </Button>
        </Tooltip>
    } else {
      removeNewRateRangeButton =
        <Button type="dashed" onClick={this.removeLastRangeSet} block>
          {/*<h3>*/}
          {/*<Icon type="delete" theme="twoTone" twoToneColor={"#506feb"} onClick={this.removeLastRangeSet}/>*/}
          <Icon type="minus-circle"/>Remove last rate range
          {/*</h3>*/}
        </Button>
    }

    return removeNewRateRangeButton;
  };

  removeLastRangeSet = (e) => {
    let field = this.state.internalTradeBuyRates
    field.pop();
    field[field.length - 1].range_to = null
    this.setState({
      internalTradeBuyRates: field
    })
  };

  // handlePetSelection(e) {
  //     const newSelection = e.target.value;
  //     let newSelectionArray;
  //     if (this.state.withDrawalRate.indexOf(newSelection) > -1) {
  //         newSelectionArray = this.state.withDrawalRate.filter(s => s !== newSelection)
  //     } else {
  //         newSelectionArray = [...this.state.withDrawalRate, newSelection];
  //     }

  // }

  handleClearForm = (e) => {
    e.preventDefault();
    this.setState({
      depositRate: this.state.defaultData.deposit,
      withDrawalRate: this.state.defaultData.withdrawal,
      interCpTransferOut: this.state.defaultData.inter_cp_transfer_out,
      interCpTransferIn: this.state.defaultData.inter_cp_transfer_in,
      internalTransferOut: this.state.defaultData.internal_transfer_out,
      internalTransferIn: this.state.defaultData.internal_transfer_in,
      storage: this.state.defaultData.storage,
      internalTradeBuyRates: this.state.defaultData.internal_trade["BUY"],
      internalTradeSellRates: this.state.defaultData.internal_trade["SELL"],
      interCpTradeBuyRates: this.state.defaultData.inter_cp_trade["BUY"],
      interCpTradeSellRates: this.state.defaultData.inter_cp_trade["SELL"]
    });
  };
  handleFormSubmit = (e) => {
    e.preventDefault();
    let headers = setRequestHeaders();
    const storeState = store.getState();
    headers["user-id"] = storeState.cognito.user.username;
    console.log(storeState);

    const internal_trade = {
      BUY: this.state.internalTradeBuyRates,
      SELL: this.state.internalTradeSellRates
    };

    const inter_cp_trade = {
      BUY: this.state.interCpTradeBuyRates,
      SELL: this.state.interCpTradeSellRates
    };

    const rate = [{
      name: this.state.defaultData.name,
      product: this.state.defaultData.product,
      deposit: this.state.depositRate,
      withdrawal: this.state.withDrawalRate,
      inter_cp_transfer_out: this.state.interCpTransferOut,
      inter_cp_transfer_in: this.state.interCpTransferIn,
      internal_transfer_out: this.state.internalTransferOut,
      internal_transfer_in: this.state.internalTransferIn,
      storage: this.state.storage,
      internal_trade: internal_trade,
      inter_cp_trade: inter_cp_trade
    }];

    const formPayload = {
      active: this.state.cpActive,
      cpId: this.state.cpId,
      cpName: this.state.cpName,
      rate: rate
    };

    this.setState({
      defaultData: formPayload.rate[0]
    });

    Axios.post(updateAdminRateURL, formPayload, {headers})
      .then(function (response) {
        console.log(response);
        message.info(`Rates updated successfully for ${formPayload.cpName}`, 5);
      })
      .catch(function (error) {
        console.log(error);
        message.info(`Error updating rates for ${formPayload.cpName}`, 5);
      });
    console.log('Sending this data to update in database:', formPayload);
  };

  // disableSubmitHandler = (e) => {
  //   return typeof this.state.internalTradeBuyRates[this.state.internalTradeBuyRates.length - 1] != "undefined" ||
  //   typeof this.state.internalTradeSellRates[this.state.internalTradeSellRates.length - 1] != "undefined" ||
  //   typeof this.state.interCpTradeBuyRates[this.state.interCpTradeBuyRates.length - 1] != "undefined" ||
  //   typeof this.state.interCpTradeSellRates[this.state.interCpTradeSellRates.length - 1] != "undefined" ?
  //     (this.state.internalTradeBuyRates[this.state.internalTradeBuyRates.length - 1].range_to != null ||
  //       this.state.internalTradeSellRates[this.state.internalTradeSellRates.length - 1].range_to != null ||
  //       this.state.interCpTradeBuyRates[this.state.interCpTradeBuyRates.length - 1].range_to != null ||
  //       this.state.interCpTradeSellRates[this.state.interCpTradeSellRates.length - 1].range_to != null) : false;
  // };

  render() {

    return (
      <form onSubmit={this.handleFormSubmit}>
        <Row style={{
          marginTop: '2px'
        }}>
          <Col span={3}>
            Internal Trade Rates:
          </Col>
          <Col span={20}>
            <Row>
              <Col span={1}>
                BUY:
              </Col>
              <Row>
                <Col span={18}>
                  <RateRangeSetter
                    rates={this.state.internalTradeBuyRates}
                    inputType={'number'}
                    name={'rates'}
                    ratePlaceholder={'Enter Rate'}
                    fromRangePlaceholder={'Enter From Range'}
                    toRangePlaceholder={'Enter To Range'}
                    addonBeforeRate={'Rate'}
                    addonAfterRate={'bps'}
                    addonBeforeFromRange={'From'}
                    addonAfterFromRange={'Oz'}
                    addonBeforeToRate={'To'}
                    addonAfterToRate={'Oz'}
                    controlRateFunc={this.handleInternalBuyRateChange}
                    controlFromRangeFunc={this.handleInternalBuyFromRangeChange}
                    controlToRangeFunc={this.handleInternalBuyToRangeChange}
                    rateRangeLength={this.state.internalTradeBuyRates.length - 1}
                    newRangeSetValidator={this.newRangeSetValidator(this.state.internalTradeBuyRates, "internalTradeBuyRates")}
                    removeRangeSetValidator={this.removeRangeSetValidator(this.state.internalTradeBuyRates)}
                  />
                  <Row>
                    <Col span={9} align={"right"}>
                      {this.newRangeSetValidator(this.state.internalTradeBuyRates, "internalTradeBuyRates")}
                    </Col>
                    <Col span={2}/>
                    <Col span={9}>
                      {this.removeRangeSetValidator(this.state.internalTradeBuyRates)}
                    </Col>
                  </Row>

                </Col>
              </Row>
            </Row><Divider dashed/>
            <Row>
              <Col span={1}>
                SELL:
              </Col>
              <Col span={18}>
                <RateRangeSetter
                  rates={this.state.internalTradeSellRates}
                  inputType={'number'}
                  name={'rates'}
                  ratePlaceholder={'Enter Rate'}
                  fromRangePlaceholder={'Enter From Range'}
                  toRangePlaceholder={'Enter To Range'}
                  addonBeforeRate={'Rate'}
                  addonAfterRate={'bps'}
                  addonBeforeFromRange={'From'}
                  addonAfterFromRange={'Oz'}
                  addonBeforeToRate={'To'}
                  addonAfterToRate={'Oz'}
                  controlRateFunc={this.handleInternalSellRateChange}
                  controlFromRangeFunc={this.handleInternalSellFromRangeChange}
                  controlToRangeFunc={this.handleInternalSellToRangeChange}
                  rateRangeLength={this.state.internalTradeSellRates.length - 1}
                  newRangeSetValidator={this.newRangeSetValidator(this.state.internalTradeSellRates, "internalTradeSellRates")}
                  removeRangeSetValidator={this.removeRangeSetValidator(this.state.internalTradeSellRates)}
                />

                <Row>
                  <Col span={9} align={"right"}>
                    {this.newRangeSetValidator(this.state.internalTradeSellRates, "internalTradeSellRates")}
                  </Col>
                  <Col span={2}/>
                  <Col span={9}>
                    {this.removeRangeSetValidator(this.state.internalTradeSellRates)}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

        </Row><Divider/>

        <Row>
          <Col span={3}>
            Inter-CP-Trade Rates:
          </Col>
          <Col span={20}>
            <Row>
              <Col span={1}>
                BUY:
              </Col>
              <Col span={18}>
                <RateRangeSetter
                  rates={this.state.interCpTradeBuyRates}
                  inputType={'number'}
                  name={'rates'}
                  ratePlaceholder={'Enter Rate'}
                  fromRangePlaceholder={'Enter From Range'}
                  toRangePlaceholder={'Enter To Range'}
                  addonBeforeRate={'Rate'}
                  addonAfterRate={'bps'}
                  addonBeforeFromRange={'From'}
                  addonAfterFromRange={'Oz'}
                  addonBeforeToRate={'To'}
                  addonAfterToRate={'Oz'}
                  controlRateFunc={this.handleInterCpBuyRateChange}
                  controlFromRangeFunc={this.handleInterCpBuyFromRangeChange}
                  controlToRangeFunc={this.handleInterCpBuyToRangeChange}
                  rateRangeLength={this.state.interCpTradeBuyRates.length - 1}
                  newRangeSetValidator={this.newRangeSetValidator(this.state.interCpTradeBuyRates, "interCpTradeBuyRates")}
                  removeRangeSetValidator={this.removeRangeSetValidator(this.state.interCpTradeBuyRates)}
                />

                <Row>
                  <Col span={9} align={"right"}>
                    {this.newRangeSetValidator(this.state.interCpTradeBuyRates, "interCpTradeBuyRates")}
                  </Col>
                  <Col span={2}/>
                  <Col span={9}>
                    {this.removeRangeSetValidator(this.state.interCpTradeBuyRates)}
                  </Col>
                </Row>
              </Col>
            </Row><Divider dashed/>
            <Row>
              <Col span={1}>
                SELL:
              </Col>
              <Col span={18}>
                <RateRangeSetter
                  rates={this.state.interCpTradeSellRates}
                  inputType={'number'}
                  name={'rates'}
                  ratePlaceholder={'Enter Rate'}
                  fromRangePlaceholder={'Enter From Range'}
                  toRangePlaceholder={'Enter To Range'}
                  addonBeforeRate={'Rate'}
                  addonAfterRate={'bps'}
                  addonBeforeFromRange={'From'}
                  addonAfterFromRange={'Oz'}
                  addonBeforeToRate={'To'}
                  addonAfterToRate={'Oz'}
                  controlRateFunc={this.handleInterCpSellRateChange}
                  controlFromRangeFunc={this.handleInterCpSellFromRangeChange}
                  controlToRangeFunc={this.handleInterCpSellToRangeChange}
                  rateRangeLength={this.state.interCpTradeSellRates.length - 1}
                  newRangeSetValidator={this.newRangeSetValidator(this.state.interCpTradeSellRates, "interCpTradeSellRates")}
                  removeRangeSetValidator={this.removeRangeSetValidator(this.state.interCpTradeSellRates)}
                />

                <Row>
                  <Col span={9} align={"right"}>
                    {this.newRangeSetValidator(this.state.interCpTradeSellRates, "interCpTradeSellRates")}
                  </Col>
                  <Col span={2}/>
                  <Col span={9}>
                    {this.removeRangeSetValidator(this.state.interCpTradeSellRates)}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

        </Row><Divider/>

        <Row>
          <Col span={4}>
            Deposits:
          </Col>
          <Col span={4}>
            <SingleInput
              inputType={'number'}
              name={'deposit'}
              controlFunc={this.handleDepositRateChange}
              content={this.state.depositRate}
              placeholder={'Type first and last name here'}
              defaultVal={this.state.depositRate}
              addonBefore={'$'}
              addonAfter={'/Transaction'}/>
          </Col>
          <Col span={1}>
            &nbsp;
          </Col>
          <Col span={3}>
            Withdrawals:
          </Col>
          <Col span={4}>
            <SingleInput
              inputType={'number'}
              name={'withdrawal'}
              controlFunc={this.handleWithdrawalRateChange}
              content={this.state.withDrawalRate}
              placeholder={'Type first and last name here'}
              defaultVal={this.state.withDrawalRate}
              addonBefore={'$'}
              addonAfter={'/Transaction'}/>
          </Col>
        </Row><Divider/>

        <Row>
          <Col span={4}>
            Internal Transfers In:
          </Col>
          <Col span={4}>
            <SingleInput
              inputType={'number'}
              name={'internalTransferIn'}
              controlFunc={this.handleInternalTransferInRateChange}
              content={this.state.internalTransferIn}
              placeholder={'Type first and last name here'}
              defaultVal={this.state.internalTransferIn}
              addonBefore={'$'}
              addonAfter={'/Transaction'}/>
          </Col>
          <Col span={1}>
            &nbsp;
          </Col>
          <Col span={3}>
            Internal Transfers Out:
          </Col>
          <Col span={4}>
            <SingleInput
              inputType={'number'}
              name={'internalTransferOut'}
              controlFunc={this.handleInternalTransferOutRateChange}
              content={this.state.internalTransferOut}
              placeholder={'Type first and last name here'}
              defaultVal={this.state.internalTransferOut}
              addonBefore={'$'}
              addonAfter={'/Transaction'}/>
          </Col>
        </Row><Divider/>

        <Row>
          <Col>
            <Col span={4}>
              Inter CP Transfers In:
            </Col>
            <Col span={4}>
              <SingleInput
                inputType={'number'}
                name={'interCpTransferIn'}
                controlFunc={this.handleInterCpTransferInRateChange}
                content={this.state.interCpTransferIn}
                placeholder={'Type first and last name here'}
                defaultVal={this.state.interCpTransferIn}
                addonBefore={'$'}
                addonAfter={'/Transaction'}/>
            </Col>
            <Col span={1}>
              &nbsp;
            </Col>
            <Col span={3}>
              Inter CP Transfers Out:
            </Col>
            <Col span={4}>
              <SingleInput
                inputType={'number'}
                name={'interCpTransferOut'}
                controlFunc={this.handleInterCpTransferOutRateChange}
                content={this.state.interCpTransferOut}
                placeholder={'Type first and last name here'}
                defaultVal={this.state.interCpTransferOut}
                addonBefore={'$'}
                addonAfter={'/Transaction'}/>
            </Col>
          </Col>
        </Row><Divider/>

        <Row>
          <Col span={4}>
            Assets on Ledger:
          </Col>
          <Col span={4}>
            <SingleInput
              inputType={'number'}
              name={'assetsOnLedger'}
              controlFunc={this.handlestorageChange}
              content={this.state.storage}
              placeholder={'Type first and last name here'}
              defaultVal={this.state.storage}
              addonBefore={'$'}
              addonAfter={'/Oz'}
              step={0.0001}/>
          </Col>
        </Row>
        <Row style={{height: '20%'}}>
          <Col span={2} offset={20} align={'right'}>
            <Input
              type="submit"
              className="btn btn-primary float-right"
              value="Submit"
              disabled={
                typeof this.state.internalTradeBuyRates[this.state.internalTradeBuyRates.length - 1] != "undefined" ||
                typeof this.state.internalTradeSellRates[this.state.internalTradeSellRates.length - 1] != "undefined" ||
                typeof this.state.interCpTradeBuyRates[this.state.interCpTradeBuyRates.length - 1] != "undefined" ||
                typeof this.state.interCpTradeSellRates[this.state.interCpTradeSellRates.length - 1] != "undefined" ?
                  (this.state.internalTradeBuyRates[this.state.internalTradeBuyRates.length - 1].range_to != null ||
                    this.state.internalTradeSellRates[this.state.internalTradeSellRates.length - 1].range_to != null ||
                    this.state.interCpTradeBuyRates[this.state.interCpTradeBuyRates.length - 1].range_to != null ||
                    this.state.interCpTradeSellRates[this.state.interCpTradeSellRates.length - 1].range_to != null) : false
              }/>
          </Col>
          <Col span={2} style={{paddingLeft: 2}}>
            <Button
              className="btn btn-link float-left"
              onClick={this.handleClearForm}>Reset</Button>
          </Col>
        </Row>
      </form>
    );
  }
}

export default connect()(FormContainer);
