import React, {Component} from 'react';
import 'antd/dist/antd.css';
import {HashRouter, Link, Redirect, Route, Switch} from 'react-router-dom';
import {Provider} from 'react-redux';
import './styles/layout.scss';
import './styles/application.scss';
import store from './store';

import RestrictedRoute from '../lib/components/RestrictedRoute/RestrictedRoute';
// Auth
import Login from '../lib/auth/components/Login';
import ResetPassword from '../lib/auth/components/ResetPassword';
import ChangeTempPassword from '../lib/auth/components/ChangeTempPassword';
import APP_CONFIG from '../app_config';

import FeesPage from './components/FeesPage';
import AdminPage from './components/AdminPage';
// import FXRatesPage from './components/FXRatesPage';
import FXRatesPage from './components/FXRatesPage';
import LbmaRatesPage from './components/LbmaRates';
import ApplicationContainer from "./components/ApplicationContainer";
import {Icon, Menu} from "antd";

class Billing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDataFetched: false
    };
  }

  componentDidMount() {
    setInterval(() => {
      const refreshAuthToken = require('../lib/auth/refreshAuthToken').default;
      refreshAuthToken(store).then(() => {
      })
      // 300,0000 is 5 minutes
    }, 300000);
    console.log(this.props)
  }

  componentDidUpdate() {
    const storeState = store.getState();
    console.log("Store", storeState);

    if (APP_CONFIG.apiAuth && storeState.cognito.authenticated && !this.state.isDataFetched) {
      this.setState({isDataFetched: true});
    } else if (!APP_CONFIG.apiAuth && !this.state.isDataFetched) {
      this.setState({isDataFetched: true});
    }
  }

  render() {
    return (
      <Provider store={store}>
        <HashRouter>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/login"/>}/>
            <Route path="/login" component={Login}/>
            <Route path="/reset-password" component={ResetPassword}/>
            <Route path="/change-temp-password" component={ChangeTempPassword}/>
            <ApplicationContainer>
              <Switch>
                <RestrictedRoute path="/billing-fee" component={FeesPage}/>
                <RestrictedRoute path="/billing-admin" component={AdminPage}/>
                {/*<RestrictedRoute path="/billing-fx-old" component={FXRatesPage} />*/}
                <RestrictedRoute path="/billing-fx" component={FXRatesPage}/>
                <RestrictedRoute path="/billing-lbma" component={LbmaRatesPage}/>
              </Switch>
            </ApplicationContainer>
            <Redirect to={{pathname: '/login'}}/>
          </Switch>
        </HashRouter>
      </Provider>
    );
  }
}

export {Billing as default}
