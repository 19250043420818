import React from 'react';
import {Button, Col, Divider, Icon, Popover, Row} from 'antd';
import SingleInput from './SingleInput';

const RateRangeSetter = (props) => (
  <div className="form-group">
    {props.rates.map((temp, index) => (
      <div key={index}>
        <Row>
          <Col span={6} align={'left'}>
            <SingleInput
              key={index}
              name={index}
              inputType={'number'}
              index={index}
              content={temp.rate}
              controlFunc={props.controlRateFunc}
              placeholder={props.ratePlaceholder}
              addonBefore={props.addonBeforeRate}
              addonAfter={props.addonAfterRate}
              defaultVal={temp.rate}
              minValue={0}
            />
          </Col>
          <Col span={1}>
            &nbsp;
          </Col>
          <Col span={6} align={'left'}>
            <SingleInput
              name={index}
              inputType={'number'}
              index={index}
              disabled={true}
              content={temp.range_from}
              controlFunc={props.controlFromRangeFunc}
              placeholder={props.fromRangePlaceholder}
              addonBefore={props.addonBeforeFromRange}
              addonAfter={props.addonAfterFromRange}
              defaultVal={temp.range_from}
              step={0.001}
            />
          </Col>
          <Col span={1}>
            &nbsp;
          </Col>
          <Col span={6} align={'left'}>
            <SingleInput
              name={index}
              inputType={'number'}
              index={index}
              content={temp.range_to}
              controlFunc={props.controlToRangeFunc}
              placeholder={props.toRangePlaceholder}
              addonBefore={props.addonBeforeToRate}
              addonAfter={props.addonAfterToRate}
              defaultVal={temp.range_to}
              step={0.001}
              minValue={(temp.range_from)}
            />
          </Col>
          {/*&nbsp;&nbsp;&nbsp;*/}
          {/*<Col span={3}>*/}
          {/*  <Row>*/}
          {/*    {props.rateRangeLength === index ?*/}
          {/*      <div>*/}
          {/*        <Col span={9} align={"right"}>*/}
          {/*          {props.newRangeSetValidator}*/}
          {/*        </Col>*/}
          {/*        <Col span={1} />*/}
          {/*        <Col span={9} align={"right"}>*/}
          {/*          {props.removeRangeSetValidator}*/}
          {/*        </Col>*/}
          {/*      </div> : <div />}*/}
          {/*  </Row>*/}
          {/*</Col>*/}
        </Row>
        {props.rateRangeLength === index && temp.range_to != null ?
          <Row>
            <p style={{color: '#ff0000'}}>
              Please add a new range set and leave the To range as it is in order to submit this rates
            </p>
          </Row> : <div/>}
      </div>
    ))}

  </div>
);

export default RateRangeSetter;
