import React from 'react';
import {Button, Form, Icon, Input} from 'antd';

const FormItem = Form.Item;

const ChangePasswordForm = Form.create()((props) => {
  const {confirmLoading, handleSubmit, form} = props;
  const {getFieldDecorator} = form;

  return (
    <Form onSubmit={handleSubmit} className="login-form">
      {props.verificationCode && (
        <div>
          <h6 className="text-center">Type in the confirmation code you received in the email</h6>
          <FormItem>
            {getFieldDecorator('verificationCode', {
              rules: [{required: true, message: 'Please Enter the Verification Code'}]
            })(<Input prefix={<Icon type="user" style={{fontSize: 18}}/>} placeholder="Verification Code"/>)}
          </FormItem>
          <h6 className="text-center">Type in your new password</h6>
        </div>
      )}

      <FormItem>
        {getFieldDecorator('password', {
          rules: [{required: true, message: 'Enter New Password'}]
        })(
          <Input
            prefix={<Icon type="lock" style={{fontSize: 18}}/>}
            type="password"
            placeholder="Enter New Password"
          />
        )}
      </FormItem>
      <FormItem>
        {getFieldDecorator('password_confirmation', {
          rules: [{required: true, message: 'Confirm New Password'}]
        })(
          <Input
            prefix={<Icon type="lock" style={{fontSize: 18}}/>}
            type="password"
            placeholder="Confirm New Password"
          />
        )}
      </FormItem>
      <FormItem>
        <Button type="primary" htmlType="submit" className="submit-button" loading={confirmLoading}>
          Change Password
        </Button>
      </FormItem>
    </Form>
  );
});

export default ChangePasswordForm;
