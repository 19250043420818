import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {message} from 'antd';
import {authenticate} from '../../cognito';
import {Authentication} from '../../redux/actions';
import LoginForm from './Form';
import Logo from '../../../components/Logo';

class LoginIndex extends Component {
  state = {
    confirmLoading: false,
    showChangePasswordForm: false
  };

  saveFormRef = (form) => {
    this.form = form;
  };

  handleLogin = (e) => {
    e.preventDefault();

    this.setState({confirmLoading: true});

    this.form.validateFields((err, values) => {
      if (err) {
        this.form.resetFields();
      } else {
        authenticate(values.username, values.password)
          .then((result) => {
            // keep returned user object in the redux store
            // redirect to the dashboard is handled in Login/index.js
            this.props.loginUser(result.user);
          })
          .catch((error) => {
            if (error.message === 'newPasswordRequired') {
              // redirect to the change temp password form
              this.props.changeTempPassword(error);
              this.setState({showChangePasswordForm: true});
            } else {
              message.error(error.message);
              this.setState({confirmLoading: false});
            }
          });
      }
    });
  };

  render() {

    // Temp password has to be changed, redirect to change password form
    if (this.state.showChangePasswordForm) {
      return (
        <Redirect
          to={{
            pathname: '/change-temp-password',
            state: {fromRoute: this.props.location.pathname}
          }}
        />
      );
    }

    return (
      <div className="body-auth body-inner">
        <div className="box box-default">
          <div className="box-body">
            <section className="text-center">
              <Logo/>
            </section>
            <LoginForm
              ref={this.saveFormRef}
              handleSubmit={this.handleLogin}
              confirmLoading={this.state.confirmLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  loginUser: user => dispatch(Authentication.login(user)),
  changeTempPassword: userData => dispatch(Authentication.changeTempPassword(userData))
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(LoginIndex)
);
