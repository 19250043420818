import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
// import settings from './settings';
import authReducer from '../../lib/auth/redux/reducer';
import monthHandler from "./monthHandler";

const reducers = combineReducers({
  cognito: authReducer,
  routing: routerReducer,
  monthHandler: monthHandler
});

export {reducers as default}
