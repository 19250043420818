import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import APP_CONFIG from '../../app_config';

const {cognito} = APP_CONFIG;

const cognitoConfig = {
  region: cognito.region,
  clientId: cognito.clientId,
  userPool: cognito.userPool,
  identityPool: cognito.identityPool
};

let cognitoUserPool;
if (APP_CONFIG.apiAuth) {
  cognitoUserPool = new CognitoUserPool({
    UserPoolId: cognitoConfig.userPool,
    ClientId: cognitoConfig.clientId
  });
}

const authenticate = (username, password) =>
  new Promise((resolve, reject) => {
    const creds = new AuthenticationDetails({
      Username: username,
      Password: password
    });

    const user = new CognitoUser({
      Username: username,
      Pool: cognitoUserPool
    });

    user.authenticateUser(creds, {
      onSuccess: () => {
        resolve({user});
      },
      onFailure: (error) => {
        reject(error);
      },
      newPasswordRequired: (userAttributes) => {
        reject({message: 'newPasswordRequired', userAttributes, user});
      }
    });
  });

const tokenRefresh = (user) =>
  new Promise((resolve, reject) => {
    user.getSession((err, session) => {
      const refresh_token = session.getRefreshToken();
      user.refreshSession(refresh_token, (refErr, refSession) => {
        if (refErr) {
          throw refErr
        } else {
          user.setSignInUserSession(refSession)

          user.getSession((err, newSession) => {
            if (newSession.isValid()) {
              resolve(user)
            } else {
              resolve(false)
            }
          })
        }
      })
    })
  })


const authenticateWithToken = (access_token, id_token, refresh_token = null) =>
  new Promise((resolve, reject) => {
    const AccessToken = new CognitoAccessToken({AccessToken: access_token});
    const IdToken = new CognitoIdToken({IdToken: id_token});
    const RefreshToken = new CognitoRefreshToken({RefreshToken: refresh_token});
    const sessionData = {
      IdToken,
      AccessToken,
      RefreshToken
    };
    const userSession = new CognitoUserSession(sessionData);
    const userData = {
      Username: IdToken.payload.email,
      Pool: cognitoUserPool
    };
    const user = new CognitoUser(userData);

    user.setSignInUserSession(userSession);

    user.getSession((err, session) => {
      if (session.isValid()) {
        resolve(user);
      } else {
        resolve(false);
      }
    });
  });

const setNewPassword = (cognitoUser, password, userAttributes) =>
  new Promise((resolve, reject) => {
    cognitoUser.completeNewPasswordChallenge(password, userAttributes, {
      onSuccess: () => {
        resolve();
      },
      onFailure: (error) => {
        reject(error);
      }
    });
  });

const forgotPassword = cognitoUser =>
  new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess(result) {
        console.log(`all result: ${result}`);
      },
      onFailure(err) {
        reject(err);
      },
      inputVerificationCode() {
        resolve();
      }
    });
  });

const confirmPassword = (cognitoUser, verificationCode, password) =>
  new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess(result) {
        resolve();
      },
      onFailure(err) {
        reject(err);
      }
    });
  });

const getUserAttributes = cognitoUser =>
  new Promise((resolve, reject) =>
    cognitoUser.getUserAttributes((error, result) => {
      if (error) {
        reject(error.message);
      } else {
        const attributes = {};
        for (let i = 0; i < result.length; i += 1) {
          const name = result[i].getName();
          const value = result[i].getValue();
          attributes[name] = value;
        }
        resolve(attributes);
      }
    })
  );

export {
  cognitoConfig,
  cognitoUserPool,
  authenticate,
  authenticateWithToken,
  setNewPassword,
  forgotPassword,
  confirmPassword,
  getUserAttributes,
  tokenRefresh
};
