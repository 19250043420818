import React from 'react';
import {Col, Row, Select, Spin, Tabs} from 'antd';
import axios from 'axios';
import FormContainer from './FormContainer';
import ErrorHandler from './ErrorHandler';
import APP_CONFIG from '../../app_config';
import setRequestHeaders from '../utils/setAuthToken';

const {Option} = Select;
const {TabPane} = Tabs;

class AdminPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cpList: [],
      loading: true,
      selectedCp: "Agnico Eagle Mines Limited",
      selectedCpId: '302a300506032b65700321001cf85940a1b74aee2bc591cedc250adb3db92adfd99cfd62253a8fbb65f4c71b',
      storedAllRates: [],
      selectedCpRates: [],
      isCpActive: false,
      uniqueKey: Math.random(),
      cpListObtained: true,
      ratesObtained: true
    }
  }

  componentDidMount() {
    const headers = setRequestHeaders();

    axios.get(APP_CONFIG.apiHosts + "/cp", {headers})
      .then(result => {
        const cpData = result.data;
        this.setState({
          cpList: cpData,
          // isCpActive: cpData[0].isCpActive,
          // selectedCp: cpData[0].cpName,
          // selectedCpId: cpData[0].cpId,
          cpListObtained: true
        });
        this.interceptCpChange(this.state.selectedCpId);
      }).catch(error => {
      console.log("error");
      console.log(error);
      this.setState({
        cpList: [],
        cpListObtained: [],
        loading: false
      })
    })
  }

  handleChange = (option) => {
    const calculateFeeCp = this.state.cpList.find(element => {
      return element.cpId === option;
    });

    this.setState({
      selectedCp: calculateFeeCp["cpName"],
      selectedCpId: calculateFeeCp["cpId"]
    });

    this.interceptCpChange(calculateFeeCp["cpId"]);
  };

  reloadChange = () => {
    this.setState({uniqueKey: Math.random()});
  };

  interceptCpChange = (option) => {
    const feeURL = APP_CONFIG.apiHosts + "/cp/rate/" + option;
    const headers = setRequestHeaders();

    axios.get(feeURL, {headers})
      .then(result => {
        const feeData = result.data;
        this.setState({
          storedAllRates: feeData,
          loading: false
        });
        this.setState({
          isCpActive: feeData["active"],
          selectedCpRates: feeData["rate"],
          ratesObtained: true
        });
        this.reloadChange();
      }).catch(error => {
      this.setState({
        storedAllRates: [],
        loading: false,
        ratesObtained: false
      });
      console.log(error);
    })
  };


  render() {
    const adminPageFormContent = this.state.ratesObtained ?
      <Tabs tabPosition="left">
        {this.state.selectedCpRates.map((feeData) => (
          <TabPane tab={feeData.product}
                   key={feeData.product}
          >
            <FormContainer
              key={this.state.uniqueKey}
              testProp={feeData}
              cpActive={this.state.isCpActive}
              cpName={this.state.selectedCp}
              cpId={this.state.selectedCpId}/>
          </TabPane>
        ))}
      </Tabs> :
      <ErrorHandler/>;

    const adminPageContent = this.state.cpListObtained ?
      <div>
        <Row type="flex" justify="space-between" align="middle">
          <Col span={12} align={'center'} style={{margin: '12px 12px 12px 12px'}}>
            <span style={{marginRight: 8}}>Select CP</span>
            <Select defaultValue={this.state.selectedCp}
                    style={{width: 250}} onChange={this.handleChange}>
              {this.state.cpList.map(e => (
                <Option key={e.cpId}>{e.cpName}</Option>
              ))}
            </Select>
          </Col>
        </Row>
        <br/>
        {adminPageFormContent}
      </div> : <ErrorHandler/>;

    return (
      <div style={{padding: '10px'}}>
        <Spin spinning={this.state.loading} delay={500}>
          {adminPageContent}
        </Spin>

      </div>
    )
  }
}

export {AdminPage as default}
