import React from 'react';
import {Input} from 'antd';

const SingleInput = (props) => (
  <div className="form-group">
    <label className="form-label">{props.title}</label>
    <Input
      className="form-input"
      disabled={props.disabled}
      name={props.name}
      type={props.inputType}
      value={props.content}
      index={props.index}
      onChange={props.controlFunc}
      placeholder={props.placeholder}
      addonBefore={props.addonBefore}
      addonAfter={props.addonAfter}
      defaultValue={props.defaultVal}
      step={props.step}
      min={props.minValue}/>
  </div>
);

export default SingleInput;
