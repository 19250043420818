import {
  COGNITO_CHANGE_TEMP_PASSWORD,
  COGNITO_CONFIGURE,
  COGNITO_LOGIN,
  COGNITO_LOGOUT,
  COGNITO_REFRESH
} from './actions';
import APP_CONFIG from '../../../app_config';

const INITIAL_STATE = {
  attributes: [],
  user: null,
  userPool: null,
  authenticated: false, // this property controls access to the dashboard views
  config: {
    userPool: null,
    clientId: null,
    identityPool: null
  },
  permissions: {
    CREATE_ACCOUNT: false,
    EDIT_ACCOUNT: false,
    CLOSE_ACCOUNT: false,
    GRANT_ACCOUNT_ACCESS: false,
    REVOKE_ACCOUNT_ACCESS: false,
    CANCEL_DEPOSIT: false,
    DEPOSIT_INTO_ACCOUNT: false,
    WITHDRAWAL_FROM_ACCOUNT: false,
    PERMITTED_TRANSFER: false,
    BOOK_TRADE: false,
    EDIT_TRADE: false,
    DELETE_TRADE: false,
    ACKNOWLEDGE_TRADE: false,
    REJECT_TRADE: false,
    SCHEDULE_TRADE: false,
    CANCEL_SETTLEMENT: false,
    SETTLE_NOW: false
  }
};

if (!APP_CONFIG.apiAuth) {
  Object.keys(INITIAL_STATE.permissions).forEach(key => {
    INITIAL_STATE.permissions[key] = true;
  })
}

const cognito = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COGNITO_CONFIGURE: {
      const {config, userPool} = action.config;
      return {
        ...state,
        config,
        userPool
      };
    }

    case COGNITO_REFRESH:
      return {
        ...state,
        user: action.user,
      }
    case COGNITO_LOGIN:
      return {
        ...state,
        user: action.user,
        attributes: action.attributes,
        permissions: action.permissions,
        authenticated: true
      };

    case COGNITO_LOGOUT:
      return {
        ...state,
        user: null,
        permissions: {},
        attributes: [],
        authenticated: false
      };

    case COGNITO_CHANGE_TEMP_PASSWORD:
      return {
        ...state,
        user: action.userData.user,
        attributes: action.userData.userAttributes,
        authenticated: false
      };

    default:
      return state;
  }
};

export default cognito;
