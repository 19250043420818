import React from 'react';
import {Alert} from 'antd';

const ErrorHandler = (props) => {

  const error = (props.status === 204 || props.status === 202) ?
    <Alert
      message="Warning"
      description="Data loading will happen on or after 10th of every month."
      type="warning"
      showIcon
    /> :
    <Alert
      message="Error"
      description="Failed to retrieve data. Please contact helpdesk!"
      type="error"
      showIcon
    />;

  return (
    <div>
      {error}
    </div>
  )
};

export {ErrorHandler as default}
