const GroupPermissions = {
  Viewer: {
    CREATE_ACCOUNT: false, // create account btn
    EDIT_ACCOUNT: false,
    CLOSE_ACCOUNT: false,
    GRANT_ACCOUNT_ACCESS: false, // wallet btn
    REVOKE_ACCOUNT_ACCESS: false,
    CANCEL_DEPOSIT: false,
    DEPOSIT_INTO_ACCOUNT: false,
    WITHDRAWAL_FROM_ACCOUNT: false,
    PERMITTED_TRANSFER: false,
    BOOK_TRADE: false, // add trade btn
    EDIT_TRADE: false, // edit trade btn
    DELETE_TRADE: false,
    ACKNOWLEDGE_TRADE: false, // inter cp trade ackn btn
    REJECT_TRADE: false,
    SCHEDULE_TRADE: false,
    CANCEL_SETTLEMENT: false,
    SETTLE_NOW: false
  },

  BackOffice: {
    CREATE_ACCOUNT: true,
    EDIT_ACCOUNT: true,
    CLOSE_ACCOUNT: true,
    GRANT_ACCOUNT_ACCESS: true,
    REVOKE_ACCOUNT_ACCESS: true,
    CANCEL_DEPOSIT: true,
    DEPOSIT_INTO_ACCOUNT: true,
    WITHDRAWAL_FROM_ACCOUNT: true,
    PERMITTED_TRANSFER: true,
    BOOK_TRADE: false,
    EDIT_TRADE: false,
    DELETE_TRADE: false,
    ACKNOWLEDGE_TRADE: true,
    REJECT_TRADE: true,
    SCHEDULE_TRADE: true,
    CANCEL_SETTLEMENT: true,
    SETTLE_NOW: true
  },

  FrontOffice: {
    CREATE_ACCOUNT: false,
    EDIT_ACCOUNT: false,
    CLOSE_ACCOUNT: false,
    GRANT_ACCOUNT_ACCESS: false,
    REVOKE_ACCOUNT_ACCESS: false,
    CANCEL_DEPOSIT: false,
    DEPOSIT_INTO_ACCOUNT: false,
    WITHDRAWAL_FROM_ACCOUNT: false,
    PERMITTED_TRANSFER: false,
    BOOK_TRADE: true,
    EDIT_TRADE: true,
    DELETE_TRADE: true,
    ACKNOWLEDGE_TRADE: false,
    REJECT_TRADE: false,
    SCHEDULE_TRADE: false,
    CANCEL_SETTLEMENT: false,
    SETTLE_NOW: false
  },

  // This group is for testing purposes
  // It's suppose to take the highest priority when merging group permissions
  MasterGroup: {
    CREATE_ACCOUNT: false,
    EDIT_ACCOUNT: true,
    CLOSE_ACCOUNT: false,
    GRANT_ACCOUNT_ACCESS: true,
    REVOKE_ACCOUNT_ACCESS: true,
    CANCEL_DEPOSIT: true,
    DEPOSIT_INTO_ACCOUNT: true,
    WITHDRAWAL_FROM_ACCOUNT: true,
    PERMITTED_TRANSFER: false,
    BOOK_TRADE: true,
    EDIT_TRADE: true,
    DELETE_TRADE: true,
    ACKNOWLEDGE_TRADE: false,
    REJECT_TRADE: true,
    SCHEDULE_TRADE: true,
    CANCEL_SETTLEMENT: false,
    SETTLE_NOW: false
  },

  DenyAll: {
    CREATE_ACCOUNT: false,
    EDIT_ACCOUNT: false,
    CLOSE_ACCOUNT: false,
    GRANT_ACCOUNT_ACCESS: false,
    REVOKE_ACCOUNT_ACCESS: false,
    CANCEL_DEPOSIT: false,
    DEPOSIT_INTO_ACCOUNT: false,
    WITHDRAWAL_FROM_ACCOUNT: false,
    PERMITTED_TRANSFER: false,
    BOOK_TRADE: false,
    EDIT_TRADE: false,
    DELETE_TRADE: false,
    ACKNOWLEDGE_TRADE: false,
    REJECT_TRADE: false,
    SCHEDULE_TRADE: false,
    CANCEL_SETTLEMENT: false,
    SETTLE_NOW: false
  },

  AllowAll: {
    CREATE_ACCOUNT: true,
    EDIT_ACCOUNT: true,
    CLOSE_ACCOUNT: true,
    GRANT_ACCOUNT_ACCESS: true,
    REVOKE_ACCOUNT_ACCESS: true,
    CANCEL_DEPOSIT: true,
    DEPOSIT_INTO_ACCOUNT: true,
    WITHDRAWAL_FROM_ACCOUNT: true,
    PERMITTED_TRANSFER: true,
    BOOK_TRADE: true,
    EDIT_TRADE: true,
    DELETE_TRADE: true,
    ACKNOWLEDGE_TRADE: true,
    REJECT_TRADE: true,
    SCHEDULE_TRADE: true,
    CANCEL_SETTLEMENT: true,
    SETTLE_NOW: true
  }
};

export default GroupPermissions;
