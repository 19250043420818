import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import ChangePassword from './ChangePassword';

class ChangeTempPasswordPage extends Component {
  render() {
    let fromRoute;

    if (this.props.location.state) {
      fromRoute = this.props.location.state.fromRoute;
    }

    // render this page only if came from '/login'
    return fromRoute === '/login' ? (
      <div className="page-login">
        <div className="main-body">
          <div key="user-login-logged-out">
            <div className="container">
              <ChangePassword/>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Redirect to="/login"/>
    );
  }
}

export default ChangeTempPasswordPage;
