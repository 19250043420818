import React, {useState} from 'react'
import {Menu, Icon} from "antd";
import {Link} from "react-router-dom";

const {SubMenu} = Menu;

const NavLeft = () => {

  return (
    <div>
      <Menu
        theme='dark'
        mode="inline"
        defaultSelectedKeys={"/billing-fee"}
      >
        {/*<SubMenu*/}
        {/*  key="sub1"*/}
        {/*  theme='dark'*/}
        {/*>*/}
        <Menu.Item key="/billing-fee">
          <Link to="/billing-fee"><Icon type="dollar"/>Billing</Link>
        </Menu.Item>
        {/*</SubMenu>*/}
        <SubMenu
          key="sub2"
          theme="dark"
          title={
            <span>
                <Icon type="setting"/>
                <span>Admin</span>
              </span>
          }
        >
          {/*<Menu mode='horizontal'>*/}
          <Menu.Item key="/billing-admin">
            <Link to="/billing-admin"><Icon type="form"/>CP Fee Rates</Link>
          </Menu.Item>
          {/*<Menu.Item key="/billing-fx-old">*/}
          {/*  <Link to="/billing-fx-old"><Icon type="form" />FX Rates</Link>*/}
          {/*</Menu.Item>*/}
          <Menu.Item key="/billing-fx">
            <Link to="/billing-fx"><Icon type="form"/>FX Rates</Link>
          </Menu.Item>
            <Menu.Item key="/billing-lbma">
                <Link to="/billing-lbma"><Icon type="form"/>LBMA Rates</Link>
            </Menu.Item>
          {/*</Menu>*/}
        </SubMenu>
      </Menu>
    </div>
  )
};

export {NavLeft as default}
