import React, {Component} from 'react';
import Axios from "axios";
import APP_CONFIG from "../../app_config";
import setRequestHeaders from "../utils/setAuthToken";
import {Button, Card, Col, Popconfirm, Row, Select, Input} from "antd";
import ErrorHandler from "./ErrorHandler";
import store from "../store";
import moment from "moment";
import fileDownload from "js-file-download";

const {Option} = Select;
const fxRateURL = APP_CONFIG.apiHosts + "/fx/rate?month=";
const fxRateUpdateURL = APP_CONFIG.apiHosts + "/fx/rate/update";

class FXRatesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMonth: store.getState().monthHandler.format('MMYYYY'),
      selectedCurrency: "CAD",
      selectedCurrencyFxRate: [],
      fxRate: undefined,
      currencyList: [
        {
          "id": "CAD",
          "name": "CAD"
        },
        {
          "id": "GBP",
          "name": "GBP"
        },
        {
          "id": "EUR",
          "name": "EUR"
        },
        {
          "id": "KRW",
          "name": "KRW"
        },
        {
          "id": "JPY",
          "name": "JPY"
        }],
      // currencyList: [],
      editingKey: '',
      isLoading: true,
      fxRatesObtained: true,
      status: 200,
      key: Math.random(),
      ModalText: 'Edit FX Rate',
      visible: false,
      confirmLoading: false
    };
    this.columns = [];

    store.subscribe(() => {
      // When state will be updated(in our case, when items will be fetched),
      // we will update local component state and force component to rerender
      // with new data.

      this.setState({
        selectedMonth: store.getState().monthHandler.format('MMYYYY')
      });
    });
  }

  componentDidMount() {
    const selectedMonth = this.state.selectedMonth;
    console.log(selectedMonth);
    const headers = setRequestHeaders();
    console.log(headers);

    Axios.get(fxRateURL + selectedMonth, {headers})
      .then(rates => {
        console.log(rates);
        if (rates.status === 200) {
          this.setState({
            fxRate: rates.data,
            isLoading: false,
            fxRatesObtained: true,
            status: rates.status
          });
          // this.setSelectedCurrencyFxRate(rates.data, this.state.selectedCurrency);
        } else
          this.setState({
            fxRate: [],
            isLoading: false,
            fxRatesObtained: false,
            status: rates.status
          })
      }).catch(error => {
      this.setState({
        fxRate: [],
        isLoading: false,
        fxRatesObtained: false,
        status: error.status
      })
    })
  }

  setSelectedCurrencyFxRate = (allRates, currency) => {
    // console.log(allRates.length);
    if (typeof allRates != "undefined" && allRates.length !== 0) {
      allRates.map(temp => {
        Object.keys(temp.rate).reduce((a, b) => b.rate === currency ?
          ({...a, date: temp.date, currency: temp.rate[b]}) :
          ({...a})
          , {})
      });
      const currencyFxRate = allRates.map(temp => {
        return Object.keys(temp.rate).reduce((a, b) => b === currency ?
          ({...a, date: temp.date, currency: temp.rate[b]}) :
          ({...a})
          , {})
      });
      console.log(currencyFxRate);
      this.setState({
        fxRatesObtained: true,
        selectedCurrencyFxRate: currencyFxRate
      })
    } else
      this.setState({
        fxRatesObtained: false,
        selectedCurrencyFxRate: []
      })
  };

  handleCurrencyChange = (currency) => {
    // this.setSelectedCurrencyFxRate(this.state.fxRate, currency);
    this.setState({
      key: Math.random(),
      selectedCurrency: currency
    });
  };

  monthChangeHandler = () => {
    const headers = setRequestHeaders();
    const month = this.state.selectedMonth;

    console.log(headers);

    let formattedMonth = null;
    if (typeof (month) != 'undefined' && month != null) {
      console.log(month);
      formattedMonth = month;
    } else {
      console.log("else");
      formattedMonth = this.state.selectedMonth;
    }

    Axios.get(fxRateURL + formattedMonth, {headers})
      .then(rates => {
        this.setState({
          fxRate: rates.data
        });
        this.setSelectedCurrencyFxRate(rates.data, this.state.selectedCurrency);
      });

    this.setState({
      selectedMonth: formattedMonth
    });
  };

  save = (date) => {
    // console.log(form);
    console.log(date);
    console.log(this.state.selectedCurrencyFxRate[date]);
    // this.setState({editingKey: ''})
    this.setState({
      editingKey: '',
      ModalText: 'The modal will be closed after two seconds',
      confirmLoading: true,
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      });
    }, 2000);
    let postCurrency = [];
    postCurrency.push({
      date: this.state.selectedCurrencyFxRate[date].date,
      sourceCurrency: this.state.selectedCurrency.toUpperCase(),
      exchangeRate: Number(this.state.selectedCurrencyFxRate[date].currency.userDefined) !== 0 ?
        Number(this.state.selectedCurrencyFxRate[date].currency.userDefined) : 0
    });
    console.log(postCurrency);
    const storeState = store.getState();
    let headers = setRequestHeaders();
    headers["user-id"] = storeState.cognito.user.username;
    Axios.post(fxRateUpdateURL, postCurrency, {headers})
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  cancel = () => {
    this.setState({editingKey: ''});
  };

  edit(date) {
    this.setState({editingKey: date});
  }

  isEditing = record => record.date === this.state.editingKey;

  handlePriceChange = (e) => {
    console.log(e.target);
    console.log(e.target.name);
    const temp = [...this.state.selectedCurrencyFxRate];
    const index = e.target.name;
    console.log(index);
    console.log(temp[index]);
    temp[index].currency.userDefined = e.target.value;
    this.setState({selectedCurrencyFxRate: temp});
    console.log(temp);
  };

  fxRatesDownloadHandler = () => {
    const headers = setRequestHeaders();
    Axios.get(APP_CONFIG.apiHosts + "/export/fxrates/csv?month=" + this.state.selectedMonth + "&currency=" + this.state.selectedCurrency,
      {headers}).then((response) => {
      // const fileName = response.headers["content-disposition"].match(/=(.*)/)[1];
      // const fileName = moment().format('MMM YYYY') + " " + this.state.selectedCurrency + ".csv";
      const fileName = this.state.selectedMonth + " " + this.state.selectedCurrency + ".csv";
      return fileDownload(response.data, `${fileName}`);
    }).catch((error) => {
      console.log('Error downloading CSV: ', error);
    });
  };

  gridStyle = (date) => {
    if (date === this.state.editingKey) {
      return {
        width: '16.65%',
        textAlign: 'left',
        boxShadow: "0px 4px 20px 16px rgb(235,235,235)",
        backgroundColor: "rgb(218,237,244)"
      }
    }
    return {
      width: '16.65%',
      textAlign: 'left',
      border: '2px solid rgb(245,245,245)',
      backgroundColor: "rgb(253,253,253)"
    }
  };

  render() {
    const {editingKey} = this.state;

    const dataGrid =
      this.state.fxRatesObtained ?
        <Card>
          {this.state.selectedCurrencyFxRate.map((temp, index) => (
            <Card.Grid style={this.gridStyle(temp.date)} key={index}>
              <b>
                <h4 align={"right"}
                    style={{color: "#1890ff", float: "right"}}>{temp.date.match(/(\d{1,4})-(\d{1,2})-(\d{2})/)[3]}</h4>
              </b><br/><br/>
              <Row>
                <Col span={16} align={"left"}>
                  <p>
                    Market Price:
                  </p>
                </Col>
                <Col span={8}>
                  <b>
                    {temp.currency.default}
                  </b>
                </Col>
              </Row>
              {this.isEditing(temp) ?
                <Row>
                  <Col span={16} align={"left"}>
                    <p>
                      User-Defined Price:
                    </p>
                  </Col>
                  <Col span={8}>
                    <Input
                      className="form-input"
                      name={index}
                      // type="number"
                      value={this.state.selectedCurrencyFxRate[index].currency.userDefined}
                      index={index}
                      onChange={this.handlePriceChange}
                      defaultValue={this.state.selectedCurrencyFxRate[index].currency.userDefined}
                      step={0.1}/>
                  </Col>
                </Row> :
                <Row>
                  <Col span={16} align={"left"}>
                    <font size={2}>
                      User-Defined Price:
                    </font>
                  </Col>
                  <Col span={8}>
                    <div>
                      <b>{temp.currency.userDefined}</b>
                    </div>
                  </Col>
                  <br/><br/>
                </Row>}
              <Row>
                {this.isEditing(temp) ?
                  (<span>&nbsp;&nbsp;
                      <a
                        onClick={() => this.save(index)}
                      >
                                    <Button type={"primary"} size={"small"}> Save </Button>&nbsp;
                                  </a>
                      <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(temp.date)}>
                                <a>
                                  <Button type={"dashed"} size={"small"}> Cancel </Button>
                                </a>
                            </Popconfirm>
                        </span>
                  ) : (
                    <a disabled={editingKey !== ''} onClick={() => this.edit(temp.date)}>
                      <Button type={"dashed"} size={"small"}> Edit </Button>
                    </a>
                  )}
              </Row>
            </Card.Grid>
          ))}
        </Card>
        :
        <Col span={10} offset={6}>
          <br/>
          <ErrorHandler
            status={this.state.status}/>
        </Col>;

    return (
      <div>
        <br/>
        <Row type="flex">
          &nbsp;
          <Col span={4}>
            <span style={{marginRight: 10}}>Select Currency:</span>
            <Select style={{width: 100}} value={this.state.selectedCurrency} onChange={this.handleCurrencyChange}>
              {this.state.currencyList.map(e => (
                <Option key={e.id}>{e.name}</Option>
              ))}
            </Select>
          </Col>
          <Col span={2}>
            <Button onClick={this.monthChangeHandler}>Fetch FX Rates</Button>
          </Col>
          <Col span={2} offset={1}>
            <Button onClick={this.fxRatesDownloadHandler} type={'primary'}>Download Data</Button>
          </Col>
        </Row>
        <br/>
        <form>
          {dataGrid}
        </form>

      </div>
    )
  }

}

export {FXRatesPage as default}
