import React from 'react';

export default () => (
  <svg className="logo-img" id="logo-img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1066.6667 1066.6667"
       width={"30%"}>
    <g transform="matrix(1.3333333,0,0,-1.3333333,0,1066.6667)" id="g10">
      <g transform="scale(0.1)" id="g12">
        <path
          id="path14"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M 987.895,5333.33 1757.7,6666.67 2527.5,5333.33 Z"
        />
        <path
          id="path16"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M 3297.3,6666.67 2527.5,5333.33 1757.7,6666.67 Z"
        />
        <path
          id="path18"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m 2527.5,5333.33 769.8,1333.34 769.8,-1333.34 z"
        />
        <path
          id="path20"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M 4836.89,6666.67 4067.1,5333.33 3297.3,6666.67 Z"
        />
        <path
          id="path22"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M 4067.1,5333.33 4836.89,6666.67 5606.7,5333.33 Z"
        />
        <path
          id="path24"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M 6376.5,6666.67 5606.7,5333.33 4836.89,6666.67 Z"
        />
        <path
          id="path26"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m 5606.7,5333.33 769.8,1333.34 769.8,-1333.34 Z"
        />
        <path
          id="path28"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M 2527.5,5333.33 1757.7,4000 987.895,5333.33 Z"
        />
        <path
          id="path30"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M 1757.7,4000 2527.5,5333.33 3297.3,4000 Z"
        />
        <path
          id="path32"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M 4067.1,5333.33 3297.3,4000 2527.5,5333.33 Z"
        />
        <path
          id="path34"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M 3297.3,4000 4067.1,5333.33 4836.89,4000 Z"
        />
        <path
          id="path36"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M 5606.7,5333.33 4836.89,4000 4067.1,5333.33 Z"
        />
        <path
          id="path38"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M 4836.89,4000 5606.7,5333.33 6376.5,4000 Z"
        />
        <path
          id="path40"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M 7146.3,5333.33 6376.5,4000 5606.7,5333.33 Z"
        />
        <path
          id="path42"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M 987.895,2666.67 1757.7,4000 2527.5,2666.67 Z"
        />
        <path
          id="path44"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M 3297.3,4000 2527.5,2666.67 1757.7,4000 Z"
        />
        <path
          id="path46"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M 2527.5,2666.67 3297.3,4000 4067.1,2666.67 Z"
        />
        <path
          id="path48"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M 4836.89,4000 4067.1,2666.67 3297.3,4000 Z"
        />
        <path
          id="path50"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M 2527.5,2666.67 1757.7,1333.33 987.895,2666.67 Z"
        />
        <path
          id="path52"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m 1757.7,1333.33 769.8,1333.34 769.8,-1333.34 z"
        />
        <path
          id="path54"
          style={{
            fill: 'none',
            stroke: '#f2f2f2',
            strokeWidth: '165.18800354',
            strokeLinecap: 'butt',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M 4067.1,2666.67 3297.3,1333.33 2527.5,2666.67 Z"
        />
      </g>
    </g>
  </svg>
);
