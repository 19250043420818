import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import reduxPromiseMiddleware from 'redux-promise-middleware';
import reducers from '../reducers';

const middleware = compose(
  applyMiddleware(thunk, reduxPromiseMiddleware()),
  window.devToolsExtension ? window.devToolsExtension() : f => f
);

function reduxStore(initialState) {
  return createStore(reducers, initialState, middleware);
}

const store = reduxStore();

export default store;
