import queryString from 'query-string';

let parsedQuery;

// Get url parameters
if (window.location.search) {
  parsedQuery = queryString.parse(window.location.search);
} else {
  const query = window.location.hash.split('?')[1];
  parsedQuery = queryString.parse(query);
}

// Pull only JWT related params from all url parameters
const {auth_type, x_api_key, x_twm_jwt_token} = parsedQuery;

let jwtTokenParams = {
  auth_type,
  x_api_key,
  x_twm_jwt_token
};

if (x_twm_jwt_token) {
  localStorage.setItem('jwtTokenParams', JSON.stringify(jwtTokenParams));
} else {
  // If no JWT token is provided in the url, check localStorage for it
  const localStorageJWT = localStorage.getItem('jwtTokenParams');

  if (localStorageJWT) {
    jwtTokenParams = JSON.parse(localStorageJWT);
  }
}

export default jwtTokenParams;
