import React from 'react';
import {Button, Form, Icon, Input} from 'antd';
import {Link} from 'react-router-dom';
import APP_CONFIG from '../../../../app_config';

const FormItem = Form.Item;

const LoginForm = Form.create()((props) => {
  const {confirmLoading, handleSubmit, form} = props;
  const {getFieldDecorator} = form;
  const {authProvider, cognito} = APP_CONFIG;

  // IE 10 polyfill
  if (!window.location.origin) {
    window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
  }

  const redirectUri = `${window.location.origin}${window.location.pathname.replace(/\/+$/, '')}`; // regex is to remove any trailing slashes

  return (
    <div className="login-form-container">
      {authProvider && authProvider !== false ? (
        <div style={{textAlign: 'center'}}>
          <Button
            type="primary"
            href={`${
              cognito.domain
            }/oauth2/authorize?identity_provider=${authProvider}&redirect_uri=${redirectUri}&response_type=token&client_id=${
              cognito.clientId
            }`}
          >
            {authProvider === 'Google' && <Icon type="google"/>}
            Login with&nbsp;{authProvider}
          </Button>
        </div>
      ) : (
        <Form onSubmit={handleSubmit} className="login-form">
          <FormItem>
            {getFieldDecorator('username', {
              rules: [{required: true, message: 'Please Enter Username or Email'}]
            })(<Input prefix={<Icon type="user" style={{fontSize: 18}}/>} placeholder="Username or Email"/>)}
          </FormItem>
          <FormItem>
            {getFieldDecorator('password', {
              rules: [{required: true, message: 'Please Enter Password'}]
            })(<Input prefix={<Icon type="lock" style={{fontSize: 18}}/>} type="password" placeholder="Password"/>)}
          </FormItem>
          <div className="forgot-password-link">
            <Link to="/reset-password">Forgot password?</Link>
          </div>
          <FormItem>
            <Button type="primary" htmlType="submit" className="submit-button" loading={confirmLoading}>
              Login
            </Button>
          </FormItem>
        </Form>
      )}
    </div>
  );
});

export default LoginForm;
