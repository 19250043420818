import moment from "moment";

const defaultDate = moment().subtract(1, 'months');

const monthHandler = (state = defaultDate, action) => {
  switch (action.type) {
    case "CURRENT_MONTH":
      return action.payload;
    case "SET_DATE":
      return action.payload;
    default:
      return state
  }
};

export default monthHandler
