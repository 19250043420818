import moment from "moment";

// const dateFormat = 'MMMM YYYY';
let date = moment().subtract(1, 'months').format('MMYYYY');

export const getDate = () => {
  // const currentMonth = moment().subtract(1, 'months').format('MMYYYY');
  console.log(date);
  return {
    type: "CURRENT_MONTH",
    payload: date
  }
};

export const setDate = (data) => {
  console.log("Inside Set date", data);
  date = data;
  return {
    type: "SET_DATE",
    payload: data
  }
};
