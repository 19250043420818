import {Authentication} from './redux/actions';
import {cognitoUserPool, tokenRefresh} from './cognito';

const refreshAuthToken = store =>
  new Promise((resolve, reject) => {
    // Look for a logged in user in local storage
    const user = cognitoUserPool.getCurrentUser();

    if (user) {
      tokenRefresh(user).then((newUser) => {
        if (newUser) {
          store.dispatch(Authentication.refresh(newUser));
          resolve();
        }
      })
    }
  });

export default refreshAuthToken;
