import React from 'react';
import {Button, Form, Icon, Input} from 'antd';

const FormItem = Form.Item;

const ResetPasswordForm = Form.create()((props) => {
  const {confirmLoading, handleSubmit, forgotPassword, form} = props;
  const {getFieldDecorator} = form;

  return (
    <Form onSubmit={handleSubmit} className="login-form">
      <h6 className="text-center">Type in your username to reset password</h6>
      <br/>
      <FormItem>
        {getFieldDecorator('username', {
          rules: [{required: true, message: 'Please enter your username'}]
        })(<Input prefix={<Icon type="user" style={{fontSize: 18}}/>} placeholder="Username"/>)}
      </FormItem>
      <FormItem>
        <Button type="primary" htmlType="submit" className="submit-button" loading={confirmLoading}>
          Reset Password
        </Button>
      </FormItem>
    </Form>
  );
});

export default ResetPasswordForm;
