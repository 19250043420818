import {Authentication} from './redux/actions';
import store from '../../billing/store';
import APP_CONFIG from '../../app_config';

export const logOut = () => {
  const user = store.getState().cognito.user;

  user.signOut(); // use cognito's built in signOut method
  store.dispatch(Authentication.logout()); // set the user obj to null in redux store

  if (APP_CONFIG.authProvider && APP_CONFIG.authProvider !== false) {
    // logout from the identity provider using the cognito hosted logout page
    const {cognito} = APP_CONFIG;
    const redirectUri = `${window.location.origin}${window.location.pathname.replace(/\/+$/, '')}`; // regex is to remove any trailing slashes
    const logoutUrl = `${cognito.domain}/logout?client_id=${cognito.clientId}&logout_uri=${redirectUri}`;

    window.location.replace(logoutUrl);
  }
  window.location.reload();
};
