import React from 'react';
import {Button, Col, DatePicker, Dropdown, Icon, Menu, message, Row, Select, Table} from 'antd';
import axios from 'axios';
import moment from 'moment';
import ErrorHandler from "./ErrorHandler";
import APP_CONFIG from '../../app_config';
import setRequestHeaders from '../utils/setAuthToken';
import fileDownload from "js-file-download";
import store from "../store";
import {setDate} from "../store/actions";

const {Option} = Select;
const {MonthPicker} = DatePicker;

export default class FeesPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      billingPeriod: undefined,
      calculatedFee: undefined,
      cpList: [],
      isLoading: false,
      selectedCP: "All",
      // selectedMonth: moment().subtract(1, 'months').format('MM YYYY'),
      selectedMonth: store.getState().monthHandler.format('MM YYYY'),
      showTable: true,
      feeDataObtained: true,
      status: 200
    };

    store.subscribe(() => {
      // When state will be updated(in our case, when items will be fetched),
      // we will update local component state and force component to rerender
      // with new data.

      this.setState({
        selectedMonth: store.getState().monthHandler.format('MM YYYY')
      });
    });

  }

  componentDidMount() {
    // const cpURL = process.env.REACT_APP_BILLING_SYSTEM_DOMAIN + "/cp";
    const cpURL = APP_CONFIG.apiHosts + "/cp";
    const headers = setRequestHeaders();

    axios.get(cpURL, {headers})
      .then(result => {
        const data = result.data;
        let allCpList = [{
          "cpName": "All",
          "cpId": "All"
        }];
        allCpList = allCpList.concat(data);
        this.setState({
          cpList: allCpList,
          selectedCP: allCpList[0].cpName
        });

      })
  }

  getColumns = (data, level) => {

    let tableColumns = [];

    if (!data) return tableColumns;

    const columns = [
      {displayName: 'Firm', propertyName: 'firm',},
      {displayName: 'Traded Product', propertyName: 'tradedProduct'},
      {displayName: 'Quoted Product', propertyName: 'quotedProduct'},
      {displayName: 'Type', propertyName: 'type'},
      {displayName: 'Buy/Sell', propertyName: 'side'},
      {displayName: 'Fee Rate', propertyName: 'feeRate', style: 'right'},
      {displayName: 'Number of Transactions', propertyName: 'noOfTransactions', style: 'right'},
      {displayName: 'Traded Amount', propertyName: 'tradedAmount', style: 'right'},
      {displayName: 'Quoted Amount', propertyName: 'quotedAmount', style: 'right'},
      {displayName: 'Quoted Amount in USD', propertyName: 'quotedAmountInUSD', style: 'right'},
      {displayName: 'Fees', propertyName: 'fee', style: 'right'}
    ];

    if (level) {
      data = data[level];
    }

    columns.filter((element) => {
      if (typeof data[0] != "undefined") {
        if (data[0].hasOwnProperty(element.propertyName)) {
          tableColumns.push({
            title: element.displayName,
            dataIndex: element.propertyName,
            key: element.propertyName,
            align: element.style
          })
        }
      }
      return true;
    });

    return tableColumns;
  };

  getExpandedRowData = (record) => {

    let data = this.state.calculatedFee[record.next].filter(e => {
      return e.key.startsWith(record.key);
    });

    let isNextLevelAvailable = !!data[0].next;

    if (isNextLevelAvailable) {
      return (
        <Table
          columns={this.getColumns(data, null)}
          expandedRowRender={this.getExpandedRowData}
          dataSource={data}
          pagination={false}
        />
      );
    } else {
      return (
        <Table
          columns={this.getColumns(data, null)}
          dataSource={data}
          pagination={false}
        />
      );
    }

  };

  getTableData = (data, level) => {
    if (!data) return [];
    return data[level];
  };

  handleCalculateFee = () => {
    this.setState({
      isLoading: true
    });

    const headers = setRequestHeaders();
    const selectedCP = this.state.selectedCP;
    const selectedMonth = this.state.selectedMonth.replace(' ', '');
    const selectedCpId = this.state.cpList.find(element => {
      return element.cpName === selectedCP;
    });
    // const calculateFeeURL = process.env.REACT_APP_BILLING_SYSTEM_DOMAIN + "/getCalculatedFee?cp=" + selectedCP;
    const calculateFeeURL = APP_CONFIG.apiHosts + "/fee?month=" + selectedMonth + "&cp=" + selectedCpId["cpId"];

    axios.get(calculateFeeURL, {headers})
      .then(result => {
        const data = result.data;
        console.log(result)

        if (result.status === 200) {
          this.setState({
            calculatedFee: data,
            isLoading: false,
            feeDataObtained: true,
            status: result.status
          });
        } else
          this.setState({
            calculatedFee: [],
            isLoading: false,
            feeDataObtained: false,
            status: result.status
          });
      }).catch(error => {
      console.log("The operation failed with:", error);
      this.setState({
        calculatedFee: [],
        isLoading: false,
        feeDataObtained: false,
        status: error.status
      });
    })
  };

  handleChange = (option) => {
    const calculateFeeCp = this.state.cpList.find(element => {
      return element.cpId === option;
    });
    this.setState({
      selectedCP: calculateFeeCp["cpName"]
    })
  };

  // handleMonthChange = (date) => {
  //   let formattedMonth;
  //   // const currentMonth = moment().subtract(4, 'months').format('MMYYYY');
  //   store.dispatch(setDate(date));
  //   console.log(store.dispatch(setDate(date)));
  //   // this.props.setDate(currentMonth.toString());
  //   if (typeof (date) != "undefined" && date != null) {
  //     formattedMonth = date.format('MM YYYY');
  //   } else {
  //     formattedMonth = this.state.selectedMonth;
  //   }
  //   this.setState({
  //     selectedMonth: formattedMonth
  //   })
  // };

  // handleDownloadHeader = (header) => {
  //   return header["content-disposition"].match(/=(.*)/)[1]
  // };

  handleMenuClick = (e) => {
    const headers = setRequestHeaders();
    const selectedCP = this.state.selectedCP;
    const selectedMonth = this.state.selectedMonth.replace(' ', '');
    const selectedCpId = this.state.cpList.find(element => {
      return element.cpName === selectedCP;
    });
    console.log('click', e.key);
    if (e.key === "Fees") {
      console.log("Inside if")
      axios.get(APP_CONFIG.apiHosts + "/export/fee/csv?month=" + selectedMonth + "&cp=" + selectedCpId["cpId"],
        {headers}).then((response) => {
        // const fileName = this.handleDownloadHeader(response.headers);
        const fileName = this.state.selectedMonth + " Fee.csv";
        console.log(response.data);
        console.log(fileName);
        return fileDownload(response.data, `${fileName}`);
      }).catch((error) => {
        message.info('Error downloading CSV. Could be due to no data for selected date range', 5);
        console.log('Error downloading CSV: ', error);
      });
    } else if (e.key === "Trades") {
      console.log("Download Trades");
      axios.get(APP_CONFIG.apiHosts + "/export/trades/csv?month=" + selectedMonth + "&cp=" + selectedCpId["cpId"],
        {headers}).then((response) => {
        // const fileName = this.handleDownloadHeader(response.headers);
        const fileName = this.state.selectedMonth + " Trades.csv";
        return fileDownload(response.data, `${fileName}`);
      }).catch((error) => {
        message.info('Error downloading CSV. Could be due to no data for selected date range', 5);
        console.log('Error downloading CSV: ', error);
      });
    } else if (e.key === "Transactions") {
      console.log("Download Deposits/Withdrawals/Transfers");
      axios.get(APP_CONFIG.apiHosts + "/export/transactions/csv?month=" + selectedMonth + "&cp=" + selectedCpId["cpId"],
        {headers}).then((response) => {
        // const fileName = this.handleDownloadHeader(response.headers);
        const fileName = this.state.selectedMonth + " Transactions.csv";
        return fileDownload(response.data, `${fileName}`);
      }).catch((error) => {
        message.info('Error downloading CSV. Could be due to no data for selected date range', 5);
        console.log('Error downloading CSV: ', error);
      });
    } else if (e.key === "Holdings") {
      axios.get(APP_CONFIG.apiHosts + "/export/holdings/csv?month=" + selectedMonth + "&cp=" + selectedCpId["cpId"],
        {headers}).then((response) => {
        // const fileName = this.handleDownloadHeader(response.headers);
        const fileName = this.state.selectedMonth + " Holdings.csv";
        return fileDownload(response.data, `${fileName}`);
      }).catch((error) => {
        message.info('Error downloading CSV. Could be due to no data for selected date range', 5);
        console.log('Error downloading CSV: ', error);
      });
    }
  };

  disabledDate = (current) => {
    // Can not select days after today and today
    return current && current > moment().subtract(1, 'months').endOf('day');
  };

  render() {
    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="Fees">
          <Icon type="download"/>
          Download Calculated Fees
        </Menu.Item>
        <Menu.Item key="Trades">
          <Icon type="download"/>
          Download Trades
        </Menu.Item>
        <Menu.Item key="Transactions">
          <Icon type="download"/>
          Download Deposits/Withdrawals/Transfers
        </Menu.Item>
        <Menu.Item key="Holdings">
          <Icon type="download"/>
          Download Holdings
        </Menu.Item>
      </Menu>
    );
    const feePageContent = this.state.feeDataObtained ?
      <Table
        className="components-table-demo-nested"
        columns={this.getColumns(this.state.calculatedFee, 'table')}
        dataSource={this.getTableData(this.state.calculatedFee, 'table')}
        expandedRowRender={this.getExpandedRowData}
        loading={this.state.isLoading}
        pagination={false}
        footer={() => (
          <Table
            className="components-table-demo-nested"
            columns={this.getColumns(this.state.calculatedFee, 'table')}
            dataSource={this.getTableData(this.state.calculatedFee, 'footer')}
            pagination={false}
          />
        )}
      /> :
      <ErrorHandler
        status={this.state.status}/>;

    return (
      <div>
        <div style={{margin: '0 0 16px', padding: '12px'}}>
          {/* <div> */}
          <Row type="flex" align={"stretch"}>
            {/* <Col span={8} offset={2}> */}
            {/*<Col>*/}
            {/*  <span>Select Billing Period</span>&nbsp;&nbsp;*/}
            {/*</Col>*/}
            {/*<Col span={4}>*/}
            {/*  <MonthPicker*/}
            {/*    defaultValue={store.getState().monthHandler}*/}
            {/*    disabledDate={this.disabledDate}*/}
            {/*    onChange={this.handleMonthChange}*/}
            {/*    name="selectedMonth"*/}
            {/*    format={'MMM YYYY'}/>*/}
            {/*</Col>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
            {/* </Col> */}
            <Col span={12}>
              <span>Select CP</span>&nbsp;&nbsp;
              <Select style={{width: 230}} onChange={this.handleChange} defaultValue="All">
                {this.state.cpList.map(e => (
                  <Option key={e.cpId}>{e.cpName}</Option>
                ))}
              </Select>
              &nbsp;&nbsp;&nbsp;
              <Button onClick={this.handleCalculateFee}>Calculate Fees</Button>
              <Dropdown overlay={menu}>
                <Button type="primary" style={{width: 230, float: "right"}}>
                  Download <Icon type="down"/>
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </div>
        <Col>
          {feePageContent}
        </Col>
      </div>
    );
  }
}
