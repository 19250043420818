import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import Login from './Login';
import APP_CONFIG from '../../../../app_config';

class LoginPage extends Component {
  render() {
    console.log("Inside auth login", this.props)
    let homePage = "/billing-fee";
    // if(!APP_CONFIG.hasTrading) homePage = "/billing-";

    return !this.props.cognito.authenticated && APP_CONFIG.apiAuth ? (
      <div className="page-login">
        <div className="main-body">
          <div key="user-login-logged-out">
            <div className="container">
              <Login/>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Redirect to={homePage}/>
    )
  }
}

const mapStateToProps = state => ({
  cognito: state.cognito
});

export default connect(
  mapStateToProps,
  null
)(LoginPage);
